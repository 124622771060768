var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './App.css';
import { useEffect, useState } from 'react';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import Header from './components/header';
import SimpleBottomNavigation from './components/bottomNavigation';
import { useGetUserQuery } from './redux/apiSlice/queensUser.slice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './components/loading';
import { useTranslation } from 'react-i18next';
import { NetworkStatusIndicator } from './components/networkIndicator';
import WebApp from '@twa-dev/sdk';
import Banned from './pages/unauthorized/banned';
import RegisterPage from './queensV2/pages/onboarding/RegisterPage';
import CreateProfile from './queensV2/pages/onboarding/CreateProfile';
import PersonalInfo from './queensV2/pages/onboarding/PersonalInfo';
import LookingFor from './queensV2/pages/onboarding/LookingFor';
import Social from './queensV2/pages/onboarding/Social';
import ProfilePhoto from './queensV2/pages/onboarding/ProfilePhoto';
import EarnPage from './queensV2/pages/earn';
import SwipeCardList from './queensV2/pages/candidates/SwipeCards';
import { store } from './redux/store';
import { updateUserData } from './redux/apiSlice/userInfo.slice';
import UserDetails from './queensV2/pages/userDetails';
import EditProfle from './queensV2/pages/editProfile';
import LookingForEdit from './queensV2/pages/editProfile/LookingForEdit';
import Matches from './queensV2/pages/matches';
import { TransitionGroup } from 'react-transition-group';
import PausedProfile from './queensV2/pages/userDetails/PausedProfile';
import FAQ from './queensV2/pages/faq';
import PrivacyPolicy from './queensV2/pages/policy';
import { useAuthUserMutation } from './redux/apiSlice/login.slice';
import { useCreateUserMutation } from './redux/apiSlice/tgService.slice';
function App() {
    var _this = this;
    var _a;
    var blockedPlatforms = ['macos', 'tdesktop', 'weba', 'webk', 'unknown'];
    var isDesktop = blockedPlatforms.includes(WebApp.platform);
    var _b = useState(false), isBanned = _b[0], setIsBanned = _b[1];
    var auth = useAuthUserMutation()[0];
    var _c = useState(false), madeCall = _c[0], setMadeCall = _c[1];
    var _d = useGetUserQuery(undefined, {
        skip: !((_a = WebApp.initDataUnsafe.user) === null || _a === void 0 ? void 0 : _a.id),
    }), data = _d.data, isLoading = _d.isLoading, isError = _d.isError, refetch = _d.refetch, error = _d.error;
    var createUser = useCreateUserMutation()[0];
    console.log({ WebApp: WebApp });
    console.log({ unsafeData: WebApp.initDataUnsafe });
    var navigate = useNavigate();
    var i18n = useTranslation().i18n;
    var retryRequest = function (maxRetries, delay) { return __awaiter(_this, void 0, void 0, function () {
        var retryCount, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    retryCount = 0;
                    _a.label = 1;
                case 1:
                    if (!(retryCount < maxRetries)) return [3 /*break*/, 4];
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, delay); })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, refetch()];
                case 3:
                    result = _a.sent();
                    if (!result.error)
                        return [3 /*break*/, 4];
                    retryCount++;
                    console.error("Retry attempt ".concat(retryCount, " failed"));
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCreateUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createUser().unwrap()];
                case 1:
                    _a.sent();
                    setMadeCall(true);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleAuth = function () { return __awaiter(_this, void 0, void 0, function () {
        var authToken, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, auth({ initData: WebApp.initData }).unwrap()];
                case 1:
                    authToken = (_a.sent()).authToken;
                    localStorage.setItem('authToken', authToken);
                    refetch();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Authentication failed:', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a;
        handleAuth();
        i18n.changeLanguage(((_a = WebApp.initDataUnsafe.user) === null || _a === void 0 ? void 0 : _a.language_code) || 'ru');
    }, []);
    useEffect(function () {
        var _a, _b;
        if (isError) {
            console.error('Error fetching user data, creating user and retrying...');
            if (((_a = WebApp.initDataUnsafe.user) === null || _a === void 0 ? void 0 : _a.id) &&
                !madeCall &&
                //@ts-ignore
                ((_b = error === null || error === void 0 ? void 0 : error.data) === null || _b === void 0 ? void 0 : _b.cause) === 'USER_NOT_FOUND' &&
                !!WebApp.initDataUnsafe.start_param) {
                handleCreateUser();
            }
            retryRequest(12, 5000);
        }
    }, [isError]);
    useEffect(function () {
        if (data) {
            localStorage.setItem('internalId', data.id);
            store.dispatch(updateUserData(__assign({}, data)));
            if (!data.onboarded) {
                navigate('/register');
            }
            else if (data.energy === 0 && data.daimond === 0) {
                navigate('/earn');
            }
            else if (data.isPaused) {
                navigate('/paused');
            }
        }
    }, [data]);
    var onboardPages = [
        '/profile-create',
        '/register',
        '/personal-info',
        '/looking-for',
        '/socials',
        '/profile-photo',
        '/edit-looking-for',
        '/edit-profile',
        '/paused',
        '/faq',
        '/policy',
    ];
    var allowFH = !onboardPages.includes(window.location.pathname);
    var allowH = window.location.pathname === '/';
    if (['/edit-looking-for', '/edit-profile', '/faq', '/policy'].includes(window.location.pathname)) {
        WebApp.BackButton.show();
    }
    else {
        if (WebApp.BackButton.isVisible) {
            WebApp.BackButton.hide();
        }
    }
    return (React.createElement(React.Fragment, null, isBanned ? (React.createElement(Banned, null)) : (React.createElement(React.Fragment, null, !!data ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "pseudo-root ".concat(allowH && 'candidates-tab') },
            allowH && React.createElement(Header, null),
            React.createElement(NetworkStatusIndicator, null),
            React.createElement(TransitionGroup, { component: null },
                React.createElement(Routes, null,
                    React.createElement(Route, { path: 'register', element: React.createElement(RegisterPage, null) }),
                    React.createElement(Route, { path: 'profile-create', element: React.createElement(CreateProfile, null) }),
                    React.createElement(Route, { path: 'personal-info', element: React.createElement(PersonalInfo, null) }),
                    React.createElement(Route, { path: 'looking-for', element: React.createElement(LookingFor, null) }),
                    React.createElement(Route, { path: 'socials', element: React.createElement(Social, null) }),
                    React.createElement(Route, { path: 'profile-photo', element: React.createElement(ProfilePhoto, null) }),
                    React.createElement(Route, { path: '/', element: React.createElement(SwipeCardList, { onboarded: data === null || data === void 0 ? void 0 : data.onboarded }) }),
                    React.createElement(Route, { path: 'earn', element: React.createElement(EarnPage, null) }),
                    React.createElement(Route, { path: 'user', element: React.createElement(UserDetails, null) }),
                    React.createElement(Route, { path: 'edit-profile', element: React.createElement(EditProfle, null) }),
                    React.createElement(Route, { path: 'edit-looking-for', element: React.createElement(LookingForEdit, null) }),
                    React.createElement(Route, { path: 'matches', element: React.createElement(Matches, null) }),
                    React.createElement(Route, { path: 'paused', element: React.createElement(PausedProfile, null) }),
                    React.createElement(Route, { path: 'faq', element: React.createElement(FAQ, null) }),
                    React.createElement(Route, { path: 'policy', element: React.createElement(PrivacyPolicy, null) }))),
            React.createElement(ToastContainer, null)),
        allowFH && React.createElement(SimpleBottomNavigation, null))) : (React.createElement(Loading, null))))));
}
export default App;

import { Box, Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import queensLogo from '../../../assets/queens-logo.svg';
import useUserId from '../../../hooks/useUserId';
import { useDefineFinishedStepMutation } from '../../../redux/apiSlice/queensUser.slice';
import { EOnboardingSteps } from '../../../types/queens.types';
var RegisterPage = function () {
    var define = useDefineFinishedStepMutation()[0];
    var userId = useUserId();
    var navigate = useNavigate();
    var handleContinue = function () {
        define({ userId: userId, step: EOnboardingSteps.ONBOARD_WELCOME });
        navigate('/profile-create');
    };
    var t = useTranslation().t;
    return (React.createElement(motion.div, { style: { height: '100%' } },
        React.createElement(Stack, { spacing: 2, alignItems: 'center', height: '85%', justifyContent: 'center' },
            React.createElement(Box, { component: motion.div, initial: 'initial', animate: ['scaled', 'final'], transition: {
                    scale: { duration: 1, delay: 1, ease: 'easeInOut' },
                    y: { duration: 1, delay: 2, ease: 'easeInOut' },
                }, variants: {
                    initial: { scale: 0, opacity: 0, y: 150 },
                    scaled: { scale: 1, opacity: 1, y: 150 },
                    final: { scale: 1, y: 0, opacity: 1 },
                } },
                React.createElement("img", { src: queensLogo, style: {
                        width: '84px',
                        height: '101px',
                    } })),
            React.createElement(Typography, { component: motion.div, initial: { opacity: 0, y: +50 }, animate: { opacity: 1, y: 0 }, transition: { duration: 1, delay: 2.45 }, className: 'reckless register-title', sx: { WebkitTextFillColor: '#2B0505' } }, "Queens"),
            React.createElement(Typography, { component: motion.div, initial: { opacity: 0, y: +50 }, animate: { opacity: 1, y: 0 }, transition: { duration: 1, delay: 2.45 }, className: 'karla register-text-info', sx: { WebkitTextFillColor: '#2B0505' } }, t('loginDescription'))),
        React.createElement(Stack, null,
            React.createElement(Button, { component: motion.div, initial: { opacity: 0, y: +50 }, animate: { opacity: 1, y: 0 }, transition: { duration: 1, delay: 2.45 }, variant: 'contained', className: 'register-btn sf-pro-semibold register-btn-hover', sx: {
                    borderRadius: '20px',
                    backgroundColor: '#2B0505',
                    color: 'white',
                    height: '56px',
                    fontSize: '17px !important',
                    lineHeight: '20.29px',
                    letterSpacing: '1px',
                }, onClick: handleContinue }, t('create_profile_button')))));
};
export default RegisterPage;

export var UserEndpoints;
(function (UserEndpoints) {
    UserEndpoints["LETS_GO"] = "lets-go";
    UserEndpoints["GENDER"] = "gender";
    UserEndpoints["PROFILE_PICTURE"] = "profile-picture";
    UserEndpoints["SWIPES"] = "swipes";
    UserEndpoints["SWIPE"] = "swipe";
    UserEndpoints["REFERRALS"] = "referrals";
    UserEndpoints["TOP_BOARD"] = "top-board";
    UserEndpoints["CHANEL_SUBSCRIPTION"] = "chanel-subscription";
    UserEndpoints["RESTE_SWIPES"] = "reset-swipes";
    UserEndpoints["INTERESTED_IN"] = "interestedIn";
    UserEndpoints["BONUS"] = "bonus";
    UserEndpoints["COLLECT"] = "collect";
    UserEndpoints["SETTINGS"] = "settings";
    UserEndpoints["LANGUAGE"] = "language";
    UserEndpoints["MATCHES"] = "matches";
    UserEndpoints["SEE"] = "see";
    UserEndpoints["CANCEL"] = "cancel";
    UserEndpoints["BOT_NAME"] = "bot-name";
    UserEndpoints["VIOLATION"] = "violation";
    UserEndpoints["LOGIN"] = "login";
    UserEndpoints["POTENTIAL_SEX_COUPLE"] = "potential-sex-couples";
    UserEndpoints["SEX_PREFERENCE"] = "sex-preference";
    UserEndpoints["COUNTRIES"] = "countries";
    UserEndpoints["COUNTRY"] = "country";
})(UserEndpoints || (UserEndpoints = {}));

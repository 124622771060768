var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Box, CircularProgress, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useUserId from '../../hooks/useUserId';
import { useCollectMiningMutation, useStartMiningMutation, } from '../../redux/apiSlice/queensUser.slice';
import utc from 'dayjs/plugin/utc';
import { addPassiveMiningAmmount, resetClaimStats, startClaim, } from '../../redux/apiSlice/userInfo.slice';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import checkboxSvg from '../../assets/checkbox.svg';
import { abbreviateNumber } from '../../utils/general.utils';
dayjs.extend(utc);
var MiningBar = function (_a) {
    var containerRef = _a.containerRef;
    var _b = useState(false), clickedFarm = _b[0], setClickedFarm = _b[1];
    var _c = useState(false), canClaim = _c[0], setCanClaim = _c[1];
    var _d = React.useState(''), activePage = _d[0], setActivePage = _d[1];
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var _e = useSelector(function (state) { return state.userInfo.userData; }), energy = _e.energy, diamond = _e.diamond, canCollectPassiveMaining = _e.canCollectPassiveMaining, passiveMainingStartTime = _e.passiveMainingStartTime, passiveMiningEnergyEarnAmount = _e.passiveMiningEnergyEarnAmount;
    var _f = useStartMiningMutation(), startMining = _f[0], startMiningLoading = _f[1].isLoading;
    var _g = useCollectMiningMutation(), collectMining = _g[0], collectMiningLoading = _g[1].isLoading;
    var userId = useUserId();
    var handleStartMining = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isClaimAvailable && (clickedFarm || !!passiveMainingStartTime))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!(canClaim || canCollectPassiveMaining)) return [3 /*break*/, 3];
                    return [4 /*yield*/, collectMining({ userId: userId }).unwrap()];
                case 2:
                    _a.sent();
                    showToast("".concat(t('youveGot'), " \u26A1").concat(passiveMiningEnergyEarnAmount));
                    setCanClaim(false);
                    dispatch(resetClaimStats());
                    dispatch(addPassiveMiningAmmount(passiveMiningEnergyEarnAmount));
                    setClickedFarm(false);
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, startMining({ userId: userId }).unwrap()];
                case 4:
                    _a.sent();
                    dispatch(startClaim());
                    setClickedFarm(true);
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var location = useLocation();
    useEffect(function () {
        setActivePage(location.pathname);
    }, [location]);
    var showToast = function (message) {
        toast(React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
            React.createElement("img", { src: checkboxSvg, width: '15px' }),
            React.createElement(Typography, null,
                " ",
                message)), {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            closeButton: false,
        });
    };
    var isLoading = startMiningLoading || collectMiningLoading;
    var isClaimAvailable = canClaim || canCollectPassiveMaining;
    var show = ['/earn', '/matches'].includes(activePage);
    return (React.createElement(Box, { component: motion.div, initial: { opacity: 0, y: 10 }, animate: {
            opacity: show ? 1 : 0,
            y: show ? 0 : 10,
            visibility: show ? 'visible' : 'hidden',
            height: show ? 'auto' : 0,
        }, exit: { opacity: 0 }, transition: { type: 'spring', stiffness: 100, damping: 20 }, style: { overflow: 'hidden' } },
        React.createElement(Box, { sx: {
                background: '#2E0909',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '12px',
                borderTopRightRadius: '16px',
                borderTopLeftRadius: '16px',
                zIndex: -1,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Chip, { label: !isClaimAvailable &&
                        (clickedFarm || !!passiveMainingStartTime) ? (React.createElement(Countdown, { date: passiveMainingStartTime
                            ? dayjs.utc(passiveMainingStartTime).valueOf() +
                                24 * 60 * 60 * 1000
                            : Date.now() + 24 * 60 * 60 * 1000, daysInHours: true, onComplete: function () {
                            setCanClaim(true);
                        }, autoStart: true })) : (React.createElement(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            lineHeight: '22px',
                        }, className: 'sf-pro-bold' },
                        canClaim || canCollectPassiveMaining
                            ? "".concat(t('claim'), " ").concat(passiveMiningEnergyEarnAmount, " \u26A1")
                            : "".concat(t('start_farming'), " \u26A1"),
                        isLoading && (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'white' } })))), sx: {
                        background: 'transparent',
                        border: '1px solid white',
                    }, className: 'sf-pro w-700 color-white', onClick: handleStartMining }),
                React.createElement(Box, { display: 'flex', gap: 1 },
                    React.createElement(Chip, { label: "\uD83D\uDC8E ".concat(abbreviateNumber(diamond)), className: 'sf-pro-bold color-white w-700', sx: {
                            background: 'transparent',
                            color: 'white',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                        } }),
                    React.createElement(Chip, { label: "\u26A1 ".concat(abbreviateNumber(energy)), className: 'sf-pro-bold color-white w-700', sx: {
                            background: 'transparent',
                            color: 'white',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                        } }))))));
};
export default MiningBar;

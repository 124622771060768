import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
var AboutSection = function (_a) {
    var about = _a.about;
    var t = useTranslation().t;
    return (React.createElement(Box, { sx: { textAlign: 'left', my: '20px', width: '80%' } },
        React.createElement(Typography, { className: 'karla fs-18 section-title', sx: {
                wordWrap: 'break-word',
            } }, t('about')),
        React.createElement(Typography, { className: 'reckless-medium fs-28 w-500', sx: {
                wordWrap: 'break-word',
            } }, about)));
};
export default AboutSection;

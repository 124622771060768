import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Onboarding from '.';
import { updateOnboardingQuestions } from '../../../redux/apiSlice/userInfo.slice';
import { store } from '../../../redux/store';
import { EOnboardingSteps } from '../../../types/queens.types';
import StepHeader from './components/StepHeader';
var CreateProfile = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), storeName = _a.name, description = _a.description;
    var _b = useState(storeName || ''), name = _b[0], setName = _b[1];
    var _c = useState(description || ''), aboutYou = _c[0], setAboutYou = _c[1];
    var maxLength = 300;
    var handleChange = function (e) {
        setAboutYou(e.target.value);
    };
    var onNext = function () {
        store.dispatch(updateOnboardingQuestions({ name: name, aboutYou: aboutYou }));
    };
    var t = useTranslation().t;
    return (React.createElement(Onboarding, { nextPage: 'personal-info', onNext: onNext, disabled: !name || !aboutYou, step: EOnboardingSteps.ONBOARD_PROFILE },
        React.createElement(Stack, { gap: 2, className: 'w-full' },
            React.createElement(StepHeader, { title: t('create_profile_title'), step: 1 }),
            React.createElement("input", { className: 'onboarding-text-input karla  w-500', placeholder: t('name_input_placeholder'), defaultValue: name, onChange: function (e) { return setName(e.target.value); }, maxLength: 24 }),
            React.createElement("div", { className: 'textarea-container' },
                React.createElement("textarea", { className: 'onboarding-text-area karla w-500', value: aboutYou, onChange: handleChange, maxLength: maxLength, placeholder: t('description_input_placeholder') }),
                React.createElement("div", { className: 'char-counter' },
                    aboutYou.length,
                    "/",
                    maxLength)),
            React.createElement("div", null,
                React.createElement(Typography, { className: 'karla example-text', sx: {
                        fontSize: '15px !important',
                        px: '8px',
                        WebkitTextFillColor: '#7A7A7A',
                    } }, t('description_input_example'))))));
};
export default CreateProfile;

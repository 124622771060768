import { Box, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useUserId from '../../../hooks/useUserId';
import { useGetReferalsQuery } from '../../../redux/apiSlice/queensUser.slice';
import FriendsList from './components/FriendsList';
import InviteFriend from './components/InviteFriend';
import ReferalMainBoard from './components/ReferalMainBoard';
import invitedSvg from '../../../assets/invited.svg';
var Referals = function () {
    var userId = useUserId();
    var _a = useState(0), page = _a[0], setPage = _a[1];
    var _b = useState(), availableBonus = _b[0], setAvailableBonus = _b[1];
    var _c = useGetReferalsQuery({ userId: userId, page: page }, { skip: !userId }), data = _c.data, isLoading = _c.isLoading;
    // if (isLoading) {
    //   return <Loading />;
    // }
    useEffect(function () {
        if (data) {
            setAvailableBonus(data.availableBonusToCollect);
        }
    }, [data]);
    var handleOnClaim = function () {
        setAvailableBonus(0);
    };
    var handleNextPage = function (page) {
        setPage(page - 1);
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(ReferalMainBoard, { availableBonusToCollect: availableBonus || 0, overallCollectedBonus: data === null || data === void 0 ? void 0 : data.overallCollectedBonus, hasRefs: !(data === null || data === void 0 ? void 0 : data.referrals.empty), onClaim: handleOnClaim }),
        React.createElement(InviteFriend, null),
        !isLoading && (React.createElement(FriendsList, { handleNextPage: handleNextPage, pageCount: data === null || data === void 0 ? void 0 : data.referrals.totalPages, content: data === null || data === void 0 ? void 0 : data.referrals.content, empty: data === null || data === void 0 ? void 0 : data.referrals.empty, icon: invitedSvg, totalPages: (data === null || data === void 0 ? void 0 : data.referrals.totalPages) || 1 })),
        isLoading && (React.createElement(React.Fragment, null,
            React.createElement(Skeleton, { variant: 'text', height: 23, width: 100 }),
            React.createElement(Skeleton, { variant: 'rectangular', height: 67 }),
            React.createElement(Skeleton, { variant: 'rectangular', height: 67 }),
            React.createElement(Skeleton, { variant: 'rectangular', height: 67 }),
            React.createElement(Skeleton, { variant: 'rectangular', height: 67 })))));
};
export default Referals;

import React from 'react';
import HeaderSection from './HeaderSection';
import EditSection from './EditSection';
import InfoSection from './InfoSection';
import { Box } from '@mui/material';
import WebApp from '@twa-dev/sdk';
import { motion } from 'framer-motion';
var UserDetails = function () {
    var screenHeight = WebApp.viewportHeight;
    return (React.createElement(Box, { component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 }, sx: { gap: 3, height: 'calc(100vh - 120px )', overflowY: 'scroll' }, display: 'flex', flexDirection: 'column', pt: screenHeight <= 800 ? '15%' : '12vh', flex: 1 },
        React.createElement(HeaderSection, null),
        React.createElement(EditSection, null),
        React.createElement(InfoSection, null)));
};
export default UserDetails;

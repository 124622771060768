var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from 'react';
import MainImage from './MainImage';
import SecondaryImage from './SecondaryImage';
import { toast } from 'react-toastify';
import { useUploadFilesMutation } from '../../redux/apiSlice/storage.slice';
import { replaceToCDN } from '../../utils/general.utils';
var blobMapper = {};
function ImageUpload(_a) {
    var _this = this;
    var onAddImage = _a.onAddImage, handleRemoveImg = _a.handleRemoveImg, _b = _a.mainPhoto, mainPhoto = _b === void 0 ? '' : _b, _c = _a.initialImages, initialImages = _c === void 0 ? [] : _c;
    var _d = useState(initialImages), images = _d[0], setImages = _d[1];
    var _e = useState(false), isMainClicked = _e[0], setIsMainClicked = _e[1];
    var _f = useState(mainPhoto), mainImage = _f[0], setMainImage = _f[1];
    var _g = useState(0), nextStepCount = _g[0], setNextStepCount = _g[1];
    var _h = useState(false), imageProcessingLoading = _h[0], setImageProcessingLoading = _h[1];
    var _j = useState(null), clickedImageIndex = _j[0], setClickedImageIndex = _j[1];
    var inputRef = useRef(null);
    var _k = useUploadFilesMutation(), uploadFile = _k[0], _l = _k[1], error = _l.error, uploadLoading = _l.isLoading;
    var isLoading = imageProcessingLoading || uploadLoading;
    var handleUploadClick = function (event) { return __awaiter(_this, void 0, void 0, function () {
        var file, validTypes, MAX_FILE_SIZE_MB, formData, img, resizeImage, resizedFile, url, cdnUrl_1, reader, error_1;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!event.target.files)
                        return [2 /*return*/];
                    if (images.length > 8) {
                        showToast('Maximum of 9 images can be uploaded');
                        return [2 /*return*/];
                    }
                    file = event.target.files[0];
                    if (!file)
                        return [2 /*return*/];
                    validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                    if (!validTypes.includes(file.type)) {
                        console.error('Invalid file type. Only JPG, JPEG, and PNG are allowed.');
                        showToast('Invalid file type. Only JPG, JPEG, and PNG are allowed.');
                        return [2 /*return*/];
                    }
                    MAX_FILE_SIZE_MB = 10;
                    if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
                        console.error("File size exceeds ".concat(MAX_FILE_SIZE_MB, " MB. Please upload a smaller file."));
                        showToast("File size exceeds ".concat(MAX_FILE_SIZE_MB, " MB. Please upload a smaller file."));
                        return [2 /*return*/];
                    }
                    formData = new FormData();
                    img = new Image();
                    resizeImage = function (file) { return __awaiter(_this, void 0, void 0, function () {
                        var bitmap, canvas_1, ctx, MAX_WIDTH, MAX_HEIGHT, width, height, error_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, createImageBitmap(file)];
                                case 1:
                                    bitmap = _a.sent();
                                    canvas_1 = document.createElement('canvas');
                                    ctx = canvas_1.getContext('2d');
                                    MAX_WIDTH = 1280;
                                    MAX_HEIGHT = 1280;
                                    width = bitmap.width;
                                    height = bitmap.height;
                                    if (width > height) {
                                        if (width > MAX_WIDTH) {
                                            height *= MAX_WIDTH / width;
                                            width = MAX_WIDTH;
                                        }
                                    }
                                    else {
                                        if (height > MAX_HEIGHT) {
                                            width *= MAX_HEIGHT / height;
                                            height = MAX_HEIGHT;
                                        }
                                    }
                                    canvas_1.width = width;
                                    canvas_1.height = height;
                                    ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(bitmap, 0, 0, width, height);
                                    return [2 /*return*/, new Promise(function (resolve, reject) {
                                            canvas_1.toBlob(function (blob) {
                                                if (blob) {
                                                    resolve(blob);
                                                }
                                                else {
                                                    reject(new Error('Canvas is empty'));
                                                }
                                            }, 'image/webp', 0.7);
                                        })];
                                case 2:
                                    error_2 = _a.sent();
                                    console.error('Error resizing image:', JSON.stringify(error_2));
                                    return [2 /*return*/, file];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, resizeImage(file)];
                case 2:
                    resizedFile = _a.sent();
                    formData.append('file', resizedFile);
                    return [4 /*yield*/, uploadFile(formData).unwrap()];
                case 3:
                    url = _a.sent();
                    cdnUrl_1 = replaceToCDN(url);
                    onAddImage(isMainClicked, cdnUrl_1);
                    reader = new FileReader();
                    reader.onload = function (e) {
                        var _a;
                        var imageUrl = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result;
                        img.src = imageUrl;
                        // @ts-ignore
                        blobMapper[imageUrl] = cdnUrl_1;
                        handleValidImage(imageUrl);
                    };
                    reader.onerror = function (error) {
                        var _a, _b, _c, _d, _e;
                        var errorDetails = {
                            type: error.type,
                            target: (_a = error.target) === null || _a === void 0 ? void 0 : _a.toString(),
                            code: (_c = (_b = error.target) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.code,
                            message: (_e = (_d = error.target) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.message,
                        };
                        console.error('Error reading the file:', errorDetails);
                        showToast('Error reading the file. Please try again.');
                    };
                    reader.readAsDataURL(file);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error during file upload:', error_1);
                    showToast('Error uploading the file. Please try again.');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var showToast = function (message) {
        toast(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            closeButton: false,
        });
    };
    var handleValidImage = function (imageUrl) {
        if (isMainClicked) {
            setMainImage(imageUrl);
            setIsMainClicked(false);
        }
        else if (clickedImageIndex !== null) {
            setImages(function (prevImages) {
                var updatedImages = __spreadArray([], prevImages, true);
                updatedImages[clickedImageIndex] = imageUrl;
                return updatedImages;
            });
            setClickedImageIndex(null);
        }
        else {
            setImages(function (prevImages) { return __spreadArray(__spreadArray([], prevImages, true), [imageUrl], false); });
        }
    };
    var handleFrameClick = function (index) {
        var _a;
        setIsMainClicked(false);
        setClickedImageIndex(index);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    useEffect(function () {
        if (images.length >= 2 && !(images.length % 2)) {
            setNextStepCount(images.length);
        }
    }, [images]);
    useEffect(function () {
        if (initialImages.length) {
            var adjustedLength = initialImages.length % 2 !== 0
                ? initialImages.length - 1
                : initialImages.length;
            setNextStepCount(adjustedLength);
        }
    }, []);
    var handleDeleteImage = function (deletedUrl, isMain) {
        //@ts-ignore
        handleRemoveImg(isMain, blobMapper[deletedUrl] || deletedUrl);
        if (isMain) {
            setMainImage(undefined);
        }
        else {
            setImages(function (o) { return o.filter(function (img) { return img !== deletedUrl; }); });
        }
    };
    return (React.createElement("div", { style: {
            border: '1px solid #DFDFDF',
            borderRadius: '16px',
            backgroundColor: 'white',
            display: 'flex',
        } },
        React.createElement("div", { style: { borderRight: 'solid 1px #dfdfdf', width: '50%' } },
            React.createElement(React.Fragment, null,
                React.createElement(MainImage, { onClick: function () {
                        var _a;
                        setIsMainClicked(true);
                        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
                    }, removeImg: handleDeleteImage, url: mainImage, isLoading: isLoading }),
                images.length >= 2 &&
                    Array.from(Array(nextStepCount).keys()).map(function (i) {
                        return i % 2 === 0 ? (React.createElement(SecondaryImage, { length: i, removeImg: handleDeleteImage, key: i, onClick: function () { return handleFrameClick(i + 2); }, url: images[i + 2], isLoading: isLoading })) : null;
                    }))),
        React.createElement("div", { style: { width: '50%' } },
            React.createElement(React.Fragment, null,
                React.createElement(SecondaryImage, { onClick: function () { return handleFrameClick(0); }, removeImg: handleDeleteImage, url: images[0], isLoading: isLoading, length: 0 }),
                React.createElement(SecondaryImage, { onClick: function () { return handleFrameClick(1); }, removeImg: handleDeleteImage, url: images[1], isLoading: isLoading, length: 1 }),
                images.length >= 2 &&
                    Array.from(Array(nextStepCount).keys()).map(function (i) {
                        return i % 2 !== 0 ? (React.createElement(SecondaryImage, { isLoading: isLoading, removeImg: handleDeleteImage, key: i, length: i, onClick: function () { return handleFrameClick(i + 2); }, url: images[i + 2] })) : null;
                    }))),
        React.createElement("input", { accept: 'image/jpeg, image/jpg, image/png', type: 'file', ref: inputRef, style: { display: 'none' }, onChange: handleUploadClick })));
}
export default ImageUpload;

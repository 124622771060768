import { IconButton, Chip, Box } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { reactions } from '../../data/general.data';
import { EReactionType, EValue } from '../../types/genral.types';
var ReactionBar = function (_a) {
    var onReact = _a.onReact;
    return (React.createElement(Box, { component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 }, sx: {
            position: 'fixed',
            bottom: '18vh',
            right: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
        } }, reactions.map(function (reaction, index) { return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
        } },
        React.createElement(IconButton, { key: index, sx: {
                background: 'white',
                width: 54,
                height: 54,
                fontSize: '32px',
                paddingBottom: '10px',
            }, className: 'reactions', onClick: function () {
                onReact(reaction.reactionType === EReactionType.DISLIKE
                    ? 'left'
                    : 'right', reaction.actionType, reaction.label);
            } }, reaction.icon),
        React.createElement(Chip, { label: "".concat(reaction.type === EValue.ENERGY ? '⚡' : '💎', " ").concat(reaction.label), className: 'sf-pro-bold color-white w-700 disable-elipsis fs-11', sx: {
                background: '#00000080',
                color: 'white',
                border: '1px solid #A9A9A9',
                position: 'absolute',
                bottom: '-20px',
                boxShadow: '0px 4px 44px 0px #FFADD41A',
                backdropFilter: 'blur(44px)',
            } }))); })));
};
export default ReactionBar;

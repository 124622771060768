var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Onboarding from '.';
import ImageUpload from '../../../components/imageUpload';
import useUserId from '../../../hooks/useUserId';
import { useOnboardMutation, useUpdateProfileMutation, } from '../../../redux/apiSlice/queensUser.slice';
import { EOnboardingSteps } from '../../../types/queens.types';
import StepHeader from './components/StepHeader';
var ProfilePhoto = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), mainPicture = _a.primaryProfilePicture, gallery = _a.pictureGallery;
    var storeImageCount = mainPicture && Array.isArray(gallery) ? gallery.length + 1 : 0;
    var _b = useState(storeImageCount), imagesCount = _b[0], setImagesCount = _b[1];
    var data = useSelector(function (state) { return state.userInfo; });
    var _c = useState(mainPicture), primaryProfilePicture = _c[0], setPrimaryProfilePicture = _c[1];
    var _d = useState(gallery || []), pictureGallery = _d[0], setPictureGalery = _d[1];
    var _e = useOnboardMutation(), onboardUser = _e[0], isLoading = _e[1].isLoading;
    var updateProfile = useUpdateProfileMutation()[0];
    var navigate = useNavigate();
    var userId = useUserId();
    var t = useTranslation().t;
    var handleImageUpload = function (isMain, url) {
        setImagesCount(function (o) { return o + 1; });
        if (isMain) {
            setPrimaryProfilePicture(url);
        }
        else {
            setPictureGalery(function (o) { return __spreadArray(__spreadArray([], o, true), [url], false); });
        }
    };
    var handleRemoveImg = function (isMain, url) {
        setImagesCount(function (o) { return o - 1; });
        if (isMain) {
            setPrimaryProfilePicture(undefined);
        }
        else {
            setPictureGalery(function (o) { return o.filter(function (img) { return img !== url; }); });
            console.log(pictureGallery, url);
        }
    };
    var handleOnboardUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!primaryProfilePicture) {
                        showToast('Main photo is mandatory!');
                        return [2 /*return*/];
                    }
                    console.log(__assign(__assign({}, data.onboardingQuestions), { primaryProfilePicture: primaryProfilePicture, pictureGallery: pictureGallery, userId: userId }));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onboardUser(__assign(__assign({}, data.onboardingQuestions), { primaryProfilePicture: primaryProfilePicture, pictureGallery: pictureGallery, userId: userId })).unwrap()];
                case 2:
                    _a.sent();
                    navigate("/");
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var showToast = function (message) {
        toast(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            closeButton: false,
        });
    };
    return (React.createElement(Onboarding, { disabled: imagesCount < 3 || isLoading, onNext: handleOnboardUser, step: EOnboardingSteps.ONBOARD_PHOTO },
        React.createElement(Stack, { gap: 2, className: 'w-full', style: { overflowY: 'scroll', paddingBottom: '20px' } },
            React.createElement(StepHeader, { title: t('add_profile_photos_title'), step: imagesCount, endStep: 9 }),
            React.createElement(ImageUpload, { onAddImage: handleImageUpload, handleRemoveImg: handleRemoveImg, mainPhoto: primaryProfilePicture, initialImages: pictureGallery }),
            React.createElement(Typography, { className: 'karla example-text', sx: { fontSize: '15px !important', WebkitTextFillColor: '#7A7A7A' } },
                React.createElement("span", { className: 'w-700' }, t('at_least_3')),
                " ",
                t('at_least_info')))));
};
export default ProfilePhoto;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import TaskItem from '../../../components/taskItem';
import useUserId from '../../../hooks/useUserId';
import { useGetTasksQuery } from '../../../redux/apiSlice/tasks.slice';
import { useTranslation } from 'react-i18next';
import { subscriptionImageMap } from './earn.data';
var SocialTasks = function () {
    var userId = useUserId();
    var t = useTranslation().t;
    var _a = useGetTasksQuery({
        userId: userId,
    }, { skip: !userId }), data = _a.data, isLoading = _a.isLoading;
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Typography, { className: 'sf-pro-semibold ', align: 'left', sx: {
                fontSize: '17px !important',
                lineHeight: '20.29px',
            } }, t('social_tasks')),
        isLoading && (React.createElement(React.Fragment, null,
            React.createElement(Skeleton, { variant: 'rectangular', height: 75 }),
            React.createElement(Skeleton, { variant: 'rectangular', height: 75 }),
            React.createElement(Skeleton, { variant: 'rectangular', height: 75 }))),
        !isLoading &&
            (data === null || data === void 0 ? void 0 : data.map(function (task, i) { return (React.createElement(TaskItem, __assign({ key: i }, task, { img: subscriptionImageMap[task.type] }))); }))));
};
export default SocialTasks;

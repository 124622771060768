import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import WebApp from '@twa-dev/sdk';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '@mui/material/styles/styled';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';

WebApp.ready();
WebApp.enableClosingConfirmation();
WebApp.expand();
WebApp.disableVerticalSwipes();
WebApp.setHeaderColor('#F2EBEA');
const webAppIds = ['5120543283', '695570893'];

Sentry.init({
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: [
  //   'localhost',
  //   'tg.queensdating.com',
  //   /^https:\/\/api\.queensdating\.com\/api/,
  // ],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
//       blockAllMedia: false,

window.addEventListener('beforeunload', () => {
  sessionStorage.clear();
});
ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          {/* {webAppIds.includes(String(WebApp.initDataUnsafe.user?.id)) ? (
          <App />
        ) : (
          <Box
            display='flex'
            height='100vh'
            justifyContent='center'
            alignItems='center'
          >
            <Typography>Application is under maintenance</Typography>
          </Box>
        )} */}
          <ErrorBoundary
            fallback={
              <div>Oops, something went wrong. Please restart the app.</div>
            }
          >
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </LocalizationProvider>
    </I18nextProvider>
  </Provider>
);

import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider } from '@mui/material';
import faqSvg from '../../../assets/faq.svg';
import policySvg from '../../../assets/policy.svg';
import supportSvg from '../../../assets/support.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import WebApp from '@twa-dev/sdk';
var InfoSection = function () {
    var navigate = useNavigate();
    var t = useTranslation().t;
    return (React.createElement(List, { sx: { background: 'white', borderRadius: '16px' } },
        React.createElement(ListItem, { button: true, onClick: function () {
                WebApp.openTelegramLink("https://t.me/queenssup");
            } },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: supportSvg })),
            React.createElement(ListItemText, { primary: t('contact_support'), className: 'sf-pro' }),
            React.createElement(ChevronRightIcon, { sx: { fill: 'rgba(147, 147, 147, 1)' } })),
        React.createElement(Divider, { variant: 'inset', component: 'li' }),
        React.createElement(ListItem, { button: true, onClick: function () { return navigate('/faq'); } },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: faqSvg })),
            React.createElement(ListItemText, { primary: t('faq'), className: 'sf-pro' }),
            React.createElement(ChevronRightIcon, { sx: { fill: 'rgba(147, 147, 147, 1)' } })),
        React.createElement(Divider, { variant: 'inset', component: 'li' }),
        React.createElement(ListItem, { button: true, onClick: function () { return navigate('/policy'); } },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: policySvg })),
            React.createElement(ListItemText, { primary: t('privacy_policy'), className: 'sf-pro' }),
            React.createElement(ChevronRightIcon, { sx: { fill: 'rgba(147, 147, 147, 1)' } }))));
};
export default InfoSection;

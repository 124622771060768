import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import ProfileCard from '.';
var SwipeableComponent = function (_a) {
    var onSwipe = _a.onSwipe, swipeDirection = _a.swipeDirection, userInfo = _a.userInfo, onSwipeCall = _a.onSwipeCall;
    var controls = useAnimation();
    React.useEffect(function () {
        if (swipeDirection) {
            var xValue = swipeDirection === 'right' ? 2000 : -2000;
            controls
                .start({
                x: xValue,
                opacity: 0,
                transition: { duration: 0.3 },
            })
                .then(onSwipe);
        }
    }, [swipeDirection, controls, onSwipe]);
    return (React.createElement(motion.div, { animate: controls, initial: { x: 0, opacity: 1 } },
        React.createElement(ProfileCard, { userInfo: userInfo })));
};
export default SwipeableComponent;

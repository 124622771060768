import { CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import plusIcon from '../../assets/plus.svg';
import closeIcon from '../../assets/close_round.svg';
var MainImage = function (_a) {
    var url = _a.url, onClick = _a.onClick, isLoading = _a.isLoading, removeImg = _a.removeImg;
    var t = useTranslation().t;
    var _b = useState(false), clicked = _b[0], setClicked = _b[1];
    return (React.createElement("div", { className: 'upload-image-main', style: {
            backgroundImage: "".concat(url ? "url(".concat(url, ")") : 'none'),
            borderBottom: 'solid 1px #dfdfdf',
            backgroundSize: 'cover',
        }, onClick: function () {
            onClick();
            setClicked(true);
        } },
        React.createElement("div", { style: {
                border: '1px solid #E3E3E3',
                borderRadius: '50px',
                position: 'absolute',
                top: '8px',
                left: '8px',
                padding: 4,
                background: 'white',
            } },
            React.createElement(Typography, { className: 'karla w-700', sx: {
                    fontSize: '12px !important',
                    color: 'rgba(175, 175, 175, 1)',
                    WebkitTextFillColor: '#7A7A7A',
                    px: '12px',
                    py: '4px',
                    lineHeight: '14px',
                } }, t('main_photo_title'))),
        !url &&
            (isLoading && clicked ? (React.createElement(CircularProgress, null)) : (React.createElement("img", { src: plusIcon, width: 39, height: 39 }))),
        url && (React.createElement("img", { src: closeIcon, width: 24, height: 24, onClick: function (e) {
                removeImg(url, true);
                e.stopPropagation();
            }, style: {
                position: 'absolute',
                top: '5px',
                right: '5px',
            } }))));
};
export default MainImage;

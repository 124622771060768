import React, { useEffect } from 'react';
import { Typography, Container, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import WebApp from '@twa-dev/sdk';
import { motion } from 'framer-motion';
var PrivacyPolicy = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var handlerClick = function () {
        navigate('/user');
    };
    useEffect(function () {
        WebApp.BackButton.onClick(handlerClick);
        return function () { return WebApp.BackButton.offClick(handlerClick); };
    }, []);
    return (React.createElement(Container, { sx: { textAlign: 'left', py: '10%' }, component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 } },
        React.createElement(Typography, { className: 'karla', sx: { textAlign: 'center' } }, t('policy')),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(Typography, { className: 'karla' }, t('effective_date')),
        React.createElement("br", null),
        React.createElement("br", null),
        Array.from(Array(55).keys()).map(function (_, index) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { className: 'karla' }, t("policy_".concat(index + 1))),
                React.createElement("br", null)));
        })));
};
export default PrivacyPolicy;

import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { replaceToCDN } from '../../utils/general.utils';
var ImageBoxWithSkeleton = function (_a) {
    var primaryPicture = _a.primaryPicture, _b = _a.width, width = _b === void 0 ? '100%' : _b, _c = _a.height, height = _c === void 0 ? 'auto' : _c, _d = _a.borderRadius, borderRadius = _d === void 0 ? '16px' : _d, _e = _a.aspectRatio, aspectRatio = _e === void 0 ? 'auto 9/16' : _e, _f = _a.maxHeight, maxHeight = _f === void 0 ? '450px' : _f;
    var _g = useState(true), isLoading = _g[0], setIsLoading = _g[1];
    useEffect(function () {
        var img = new Image();
        img.src = primaryPicture;
        img.onload = function () { return setIsLoading(false); };
    }, [primaryPicture]);
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(Skeleton, { variant: 'rectangular', width: width, height: height, sx: { borderRadius: borderRadius, aspectRatio: aspectRatio }, animation: 'wave' })) : (React.createElement(Box, { sx: {
            width: width,
            height: height,
            borderRadius: borderRadius,
            maxHeight: maxHeight,
            position: 'relative',
            backgroundImage: "url(".concat(replaceToCDN(primaryPicture), ")"),
            aspectRatio: aspectRatio,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        } }))));
};
export default ImageBoxWithSkeleton;

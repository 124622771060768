var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Card, Typography, Box, IconButton, Button, CircularProgress, } from '@mui/material';
import { actionType } from '../../../../data/general.data';
import { useGoToChatMutation, useUnblurMutation, } from '../../../../redux/apiSlice/queensUser.slice';
import useUserId from '../../../../hooks/useUserId';
import WebApp from '@twa-dev/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { decrementEnergy } from '../../../../redux/apiSlice/userInfo.slice';
import lockSvg from '../../../../assets/lock.svg';
import { useTranslation } from 'react-i18next';
import ImageBoxWithSkeleton from '../../../../components/skeletonImage';
import { showToast } from '../../../../utils/sentry.utils';
var MatchCard = function (_a) {
    var name = _a.name, username = _a.username, actiontypes = _a.actiontypes, isBlocked = _a.isBlocked, id = _a.id, profilePicture = _a.profilePicture;
    var _b = useState(isBlocked), isBlockedProfile = _b[0], setIsBlockedProfile = _b[1];
    var _c = useState(username), chatUsername = _c[0], setChatUserName = _c[1];
    var _d = useGoToChatMutation(), goToChat = _d[0], chatLoading = _d[1].isLoading;
    var userId = useUserId();
    var dispatch = useDispatch();
    var _e = useUnblurMutation(), unblur = _e[0], isLoading = _e[1].isLoading;
    var energy = useSelector(function (state) { return state.userInfo.userData; }).energy;
    var handleUnblur = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    if (!(energy >= 10)) return [3 /*break*/, 2];
                    return [4 /*yield*/, unblur({ userId: userId, id: id }).unwrap()];
                case 1:
                    data = _a.sent();
                    dispatch(decrementEnergy(10));
                    setChatUserName(data.actor.username);
                    setIsBlockedProfile(false);
                    return [3 /*break*/, 3];
                case 2:
                    showToast("".concat(t('noEnoughEnergy'), " \u26A1"), 'default');
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleGoToChat = function (targetUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, goToChat({
                            actingUserId: userId,
                            targetUserId: targetUserId,
                        }).unwrap()];
                case 1:
                    data = _a.sent();
                    WebApp.openTelegramLink(data.chatUrl);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Could not create chat');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var t = useTranslation().t;
    return (React.createElement(Card, { sx: {
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            textAlign: 'center',
            paddingBottom: '8px',
            backgroundColor: '#F7F7F7',
            border: '2px solid white',
        } },
        React.createElement("div", { className: 'container', style: { position: 'relative' } },
            isBlockedProfile && (React.createElement("div", { style: {
                    width: '100%',
                    height: '100%',
                    backdropFilter: 'blur(20px)',
                    WebkitBackdropFilter: 'blur(20px)',
                    zIndex: 1,
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                } },
                React.createElement("img", { src: lockSvg }))),
            React.createElement(ImageBoxWithSkeleton, { primaryPicture: profilePicture, borderRadius: '0', height: '50vw' }),
            React.createElement(Box, { className: 'bottom-left reckless color-white', sx: {
                    fontSize: '20px !important',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: ' 85%',
                } }, name),
            React.createElement("div", { className: 'top-left' },
                React.createElement(IconButton, { sx: {
                        background: 'white',
                        width: 32,
                        height: 32,
                        fontSize: '22px',
                        paddingBottom: '10px',
                    }, className: 'reactions' }, Array.isArray(actiontypes)
                    ? //@ts-ignore
                        actionType[actiontypes[0]]
                    : //@ts-ignore
                        actionType[actiontypes]),
                Array.isArray(actiontypes) && actiontypes[1] && (React.createElement(IconButton, { sx: {
                        background: 'white',
                        width: 32,
                        height: 32,
                        left: '-5px',
                        fontSize: '22px',
                        paddingBottom: '10px',
                    }, className: 'reactions' }, Array.isArray(actiontypes)
                    ? //@ts-ignore
                        actionType[actiontypes[1]]
                    : actionType[actiontypes])))),
        React.createElement(Box, { display: 'flex', justifyContent: 'space-around', height: '48px', px: '8%', pt: '8px', alignContent: 'center', flexWrap: 'wrap', onClick: function () {
                if (isBlockedProfile) {
                    handleUnblur();
                }
                else {
                    chatUsername
                        ? WebApp.openTelegramLink("https://t.me/".concat(chatUsername))
                        : handleGoToChat(id);
                }
            } },
            React.createElement(Button, { disabled: chatLoading, className: 'register-btn sf-pro register-btn-hover', sx: {
                    borderRadius: '40px',
                    backgroundColor: 'black',
                    color: 'white',
                    height: '40px',
                }, fullWidth: true }, !(isBlockedProfile && !username) ? (React.createElement(Typography, { className: 'sf-pro w-700' }, t('go_to_chat'))) : (React.createElement(Typography, { className: 'sf-pro w-700' }, isLoading || chatLoading ? (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'white' } })) : ("".concat(t('unlock_inbox_profile'), " \u26A1 10"))))))));
};
export default MatchCard;

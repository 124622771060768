var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Slide, toast } from 'react-toastify';
import checkboxSvg from '../../assets/checkbox-dark.svg';
import WebApp from '@twa-dev/sdk';
import { useDispatch } from 'react-redux';
import { ETaskStatus, ETaskType } from '../../types/queens.types';
import { addPassiveMiningAmmount } from '../../redux/apiSlice/userInfo.slice';
import { useCompleteTaskMutation, usePickTaskMutation, } from '../../redux/apiSlice/tasks.slice';
import useUserId from '../../hooks/useUserId';
import { useTranslation } from 'react-i18next';
var TaskItem = function (props) {
    var title = props.title, img = props.img, energyEarn = props.energyEarn, telegramChannelUsername = props.telegramChannelUsername, status = props.status, id = props.id, type = props.type, targetUrl = props.targetUrl;
    var userId = useUserId();
    var dispatch = useDispatch();
    var _a = useState(status === ETaskStatus.IN_PROGRESS), isClaim = _a[0], setIsClaim = _a[1];
    var _b = useState(status === ETaskStatus.COMPLETED), isComplete = _b[0], setIsComplete = _b[1];
    var t = useTranslation().t;
    var _c = usePickTaskMutation(), pick = _c[0], pickLoading = _c[1].isLoading;
    var _d = useCompleteTaskMutation(), complete = _d[0], completeLoading = _d[1].isLoading;
    var isLoading = pickLoading || completeLoading;
    var handleTaskAction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    if (!(!isClaim && !isComplete)) return [3 /*break*/, 2];
                    return [4 /*yield*/, pick({ userId: userId, taskId: id }).unwrap()];
                case 1:
                    _b.sent();
                    if (type === ETaskType.TELEGRAM_CHANEL_SUBSCRIPTION &&
                        telegramChannelUsername) {
                        WebApp.openTelegramLink("https://t.me/".concat(telegramChannelUsername));
                    }
                    else {
                        if (targetUrl.includes('https://t.me')) {
                            WebApp.openTelegramLink(targetUrl);
                        }
                        else {
                            WebApp.openLink(targetUrl);
                        }
                    }
                    setIsClaim(true);
                    return [3 /*break*/, 4];
                case 2:
                    if (!isClaim) return [3 /*break*/, 4];
                    return [4 /*yield*/, complete({ userId: userId, taskId: id }).unwrap()];
                case 3:
                    _b.sent();
                    dispatch(addPassiveMiningAmmount(energyEarn));
                    toast(React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                        React.createElement("img", { src: checkboxSvg, width: '15px' }),
                        React.createElement(Typography, null,
                            " You got \u26A1 ",
                            energyEarn)), {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                        closeButton: false,
                        transition: Slide,
                    });
                    setIsClaim(false);
                    setIsComplete(true);
                    _b.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _b.sent();
                    if (e_1 && (e_1 === null || e_1 === void 0 ? void 0 : e_1.data) && ((_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.data) === null || _a === void 0 ? void 0 : _a.cause)) {
                        if (type === ETaskType.TELEGRAM_CHANEL_SUBSCRIPTION &&
                            telegramChannelUsername) {
                            WebApp.openTelegramLink("https://t.me/".concat(telegramChannelUsername));
                        }
                        else {
                            if (targetUrl.includes('https://t.me')) {
                                WebApp.openTelegramLink(targetUrl);
                            }
                            else {
                                WebApp.openLink(targetUrl);
                            }
                        }
                        // toast(
                        //   <Box display='flex' alignItems='center' gap={1}>
                        //     <Typography>
                        //       {e?.data?.cause === 'CHANEL_SUBSCRIPTION_NEEDED' &&
                        //         t('needToSubscribe')}
                        //     </Typography>
                        //   </Box>,
                        //   {
                        //     position: 'top-center',
                        //     autoClose: 5000,
                        //     hideProgressBar: true,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        //     theme: 'dark',
                        //     closeButton: false,
                        //     transition: Slide,
                        //   }
                        // );
                    }
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Box, { sx: {
            background: '#E9DED9',
            borderRadius: '10px',
            display: 'flex',
            padding: '12px',
            alignItems: 'center',
            gap: '10px',
            border: '1px solid #D4C3BE',
        } },
        React.createElement("img", { width: '24px', height: '24px', src: img, alt: '' }),
        React.createElement(Box, { display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center', width: '100%' },
            React.createElement(Box, { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left' },
                React.createElement(Typography, { className: ' karla w-700', sx: { fontSize: '14px !important', lineHeight: '16.37px' } }, title),
                React.createElement(Typography, { className: ' sf-pro-semibold', sx: {
                        fontSize: '12px',
                        lineHeight: '22px',
                    } },
                    "+\u26A1 ",
                    energyEarn)),
            React.createElement(Button, { className: "karla w-700 ".concat(isComplete && 'chip-button-complete', " ").concat(isClaim ? 'chip-button-claim' : 'chip-button '), variant: 'outlined', disabled: isComplete || isLoading, sx: {
                    padding: '16px !important',
                    width: 'fit-content',
                    fontSize: '12px !important',
                    lineHeight: '22px',
                    letterSpacing: '-0.41px',
                }, onClick: handleTaskAction, startIcon: isComplete && React.createElement("img", { src: checkboxSvg }) },
                !isClaim && !isComplete && t('start'),
                isClaim && t('claim'),
                isComplete && t('completed')))));
};
export default TaskItem;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import { abbreviateNumber } from '../../utils/general.utils';
import { motion } from 'framer-motion';
var Header = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), energy = _a.energy, diamond = _a.diamond;
    var t = useTranslation().t;
    return (React.createElement(Box, { component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 }, sx: {
            position: 'fixed',
            top: '30px',
            right: '16px',
            display: 'flex',
            gap: '8px',
            zIndex: 100,
            marginTop: '16px',
        } },
        React.createElement(Chip, { label: "\uD83D\uDC8E ".concat(abbreviateNumber(diamond)), className: 'sf-pro-bold color-white w-700', sx: { background: '#00000080', color: 'white' } }),
        React.createElement(Chip, { label: "\u26A1 ".concat(abbreviateNumber(energy)), className: 'sf-pro-bold color-white w-700', sx: { background: '#00000080', color: 'white' } })));
};
export default Header;

import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import emptySvg from '../../../../assets/matchEmpty.svg';
var EmptyState = function (_a) {
    var title = _a.title;
    var t = useTranslation().t;
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', height: '70svh', alignItems: 'center', justifyContent: 'center', gap: 1 },
        React.createElement("img", { src: emptySvg, style: {
                marginBottom: '20px',
            } }),
        React.createElement(Typography, { className: 'sf-pro-semibold', sx: {
                lineHeight: '20.29px',
                letterSpacing: '1px',
                fontSize: '17px !important',
            } }, title),
        React.createElement(Typography, { className: 'karla w-400 ', sx: {
                WebkitTextFillColor: '#7A7A7A',
                fontSize: '15px !important',
                width: '250px',
            } }, t('mutualEmptyInfo'))));
};
export default EmptyState;

import React from 'react';
import AboutSection from '../../../components/aboutSection';
import LockedSocials from '../../../components/lockedSocials';
import dummy from '../../../assets/dummy.jpg';
import { Box, Typography } from '@mui/material';
import { getAge } from '../../../utils/general.utils';
import ImageBoxWithSkeleton from '../../../components/skeletonImage';
var user = {
    name: 'Rafaella',
    age: 21,
    location: 'Dubai, UAE',
    image: dummy,
    diamonds: 2,
    energy: 100,
    compatibility: ['Art', 'Mountains'],
};
var ProfileCard = function (_a) {
    var userInfo = _a.userInfo;
    var name = userInfo.name, countryName = userInfo.countryName, cityName = userInfo.cityName, description = userInfo.description, primaryPicture = userInfo.primaryPicture, id = userInfo.id, birthDate = userInfo.birthDate, socialMedia = userInfo.socialMedia, photoGallery = userInfo.photoGallery, zodiacSign = userInfo.zodiacSign;
    return (React.createElement(Box, { sx: {
            position: 'relative',
            paddingTop: '20px',
            height: 'calc(100vh - 105px)',
            overflowY: 'scroll',
            background: '#F2EBEA',
        } },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'candidates-tab-content' },
                React.createElement(Box, { sx: { marginBottom: '20px' } },
                    React.createElement(Typography, { align: 'left', className: 'reckless-32', maxWidth: '65%', sx: {
                            wordWrap: 'break-word',
                        } },
                        name,
                        " ",
                        React.createElement("span", { className: 'reckless-light' }, getAge(birthDate))),
                    React.createElement(Typography, { variant: 'body1', align: 'left', className: 'karla w-500', sx: {
                            letterSpacing: '-0.41px',
                        } },
                        cityName,
                        ", ",
                        countryName)),
                React.createElement(Box, { position: 'relative', width: '100%', height: 'auto' },
                    React.createElement(ImageBoxWithSkeleton, { primaryPicture: primaryPicture, height: 470, borderRadius: '8px', aspectRatio: '16/9' })),
                React.createElement(AboutSection, { about: description })),
            React.createElement(ImageBoxWithSkeleton, { primaryPicture: photoGallery[0], borderRadius: '0', maxHeight: 'unset' }),
            React.createElement(Box, { sx: { gap: '8px', my: '16px', padding: '1rem' } },
                React.createElement(LockedSocials, { targetUserId: id, socialMedia: socialMedia, zodiacSign: zodiacSign })),
            photoGallery.slice(1).map(function (img, i) {
                return (React.createElement(ImageBoxWithSkeleton, { key: i, primaryPicture: img, borderRadius: '0', maxHeight: 'unset' }));
            }))));
};
export default ProfileCard;

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/material';
import WebApp from '@twa-dev/sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import inviteFriendSvg from '../../../../assets/invite-friend.svg';
import { userId } from '../../../../utils/general.utils';
var botname = import.meta.env.VITE_BOT_NAME;
var InviteDrawerContent = function () {
    var inviteLink = "t.me/".concat(botname, "/?start=").concat(userId);
    var t = useTranslation().t;
    var handleCopyInvite = function () {
        try {
            navigator.clipboard.writeText("https://".concat(inviteLink));
        }
        catch (error) {
            console.error('Failed to copy invite link:', error);
        }
    };
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
            textAlign: 'center',
            height: '60vh',
            justifyContent: 'space-evenly',
            paddingX: '20px',
            paddingY: '30px',
        } },
        React.createElement("img", { src: inviteFriendSvg }),
        React.createElement(Box, { sx: { width: '90%' } },
            React.createElement(Typography, { className: 'sf-pro-bold  w-600', sx: {
                    lineHeight: '20.29px',
                    fontSize: '17px',
                    mb: '10px',
                    letterSpacing: '1px',
                } },
                t('invite_friend_title'),
                " \uD83D\uDC8E"),
            React.createElement(Typography, { sx: {
                    lineHeight: '17.54px',
                    WebkitTextFillColor: '#7A7A7A',
                    fontSize: '15px !important',
                } }, t('invite_firned_info'))),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',
            } },
            React.createElement(Button, { className: 'register-btn sf-pro-bold register-btn-hover', sx: {
                    borderRadius: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    height: '56px',
                    lineHeight: '20.29px',
                    fontSize: '17px !important',
                }, fullWidth: true, onClick: function () {
                    WebApp.openTelegramLink("https://t.me/share/url?url=".concat(inviteLink, "&text=Join me at Queens and connect with amazing new people!"));
                } }, t('send_invite')),
            React.createElement(Button, { className: 'register-btn sf-pro-bold color-black', sx: {
                    borderRadius: '20px',
                    backgroundColor: 'white',
                    height: '56px',
                    border: '1px solid black',
                    lineHeight: '20.29px',
                    fontSize: '17px !important',
                    ':hover': {
                        backgroundColor: 'transparent !important',
                    },
                }, fullWidth: true, onClick: handleCopyInvite }, t('copy_invite_link')))));
};
export default InviteDrawerContent;

import React, { useState } from 'react';
import { Box, Tabs, Tab, Fade } from '@mui/material';
import SocialTasks from './SocialTasks';
import Referals from './Referals';
import { useTranslation } from 'react-i18next';
var EarnPage = function () {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var t = useTranslation().t;
    return (React.createElement(Box, { sx: {
            width: '100%',
        } },
        React.createElement(Box, { sx: {
                justifyContent: 'center !important',
                display: 'flex',
                width: '100%',
                background: '#E9DED9',
                borderRadius: '60px',
                padding: '2px',
            } },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": 'earn', sx: { width: '100%' } },
                React.createElement(Tab, { disableRipple: true, label: t('tasks'), sx: {
                        '&.Mui-selected': { background: 'white', borderRadius: '60px' },
                        width: '50%',
                    } }),
                React.createElement(Tab, { disableRipple: true, label: t('refs'), sx: {
                        '&.Mui-selected': { background: 'white', borderRadius: '60px' },
                        width: '50%',
                    } }))),
        React.createElement(Box, { pt: '24px', sx: {
                height: 'calc(100vh - 220px)',
                overflowY: 'scroll',
                background: '#F2EBEA',
            } },
            React.createElement(Fade, { in: value === 0, style: { display: !value ? 'block' : 'none' } },
                React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                    React.createElement(SocialTasks, null))),
            React.createElement(Fade, { in: value === 1, style: { display: value ? 'block' : 'none' } },
                React.createElement(Box, null,
                    React.createElement(Referals, null))))));
};
export default EarnPage;

var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    swipeCount: 0,
    coins: 0,
    swipeAdditionTimerStartingTime: '',
    first50: false,
    first100: false,
    botName: '',
    hasPhoto: true,
    swipedUsersToBeSent: [],
    usedSwipesCount: 0,
    sexFeatureAvailable: false,
    interestedIn: '',
    language: '',
    userId: '',
    username: '',
    firstName: '',
    lastName: '',
    gender: '',
    profilePicture: '',
    rating: 0,
    countryName: '',
};
var counterSlice = createSlice({
    name: 'counter',
    initialState: initialState,
    reducers: {
        incrementSwipeCount: function (state) {
            state.swipeCount += 1;
        },
        decrementSwipeCount: function (state) {
            state.swipeCount -= 1;
        },
        incrementCoins: function (state) {
            state.coins += 1;
        },
        decrementCoins: function (state) {
            state.coins -= 1;
        },
        setCoins: function (state, action) {
            state.coins = action.payload;
        },
        setSwipes: function (state, action) {
            state.swipeCount = action.payload;
        },
        setSwipeAdditionTimerStartingTime: function (state, action) {
            if (state.swipeCount === 0 && action.payload.isLocal) {
                state.swipeAdditionTimerStartingTime = new Date();
            }
            else {
                state.swipeAdditionTimerStartingTime = action.payload;
            }
        },
        setFirst50: function (state, action) {
            if (state.usedSwipesCount === 50) {
                state.first50 = action.payload;
            }
        },
        setFirst100: function (state, action) {
            if (state.usedSwipesCount === 100) {
                state.first100 = action.payload;
            }
        },
        setBotName: function (state, action) {
            state.botName = action.payload;
        },
        setHasPhoto: function (state, action) {
            state.hasPhoto = action.payload;
        },
        incrementUsedSwipesCount: function (state) {
            state.usedSwipesCount += 1;
        },
        setUsedSwipesCount: function (state, action) {
            state.usedSwipesCount = action.payload;
        },
        setSexFeatureAvailable: function (state, action) {
            state.sexFeatureAvailable = action.payload;
        },
        setInterestedIn: function (state, action) {
            state.interestedIn = action.payload;
        },
        setUserData: function (state, action) {
            state.language = action.payload.langauge;
            state.username = action.payload.username;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.gender = action.payload.gender;
            state.interestedIn = action.payload.interestedIn;
            state.profilePicture = action.payload.profilePicture;
            state.swipeCount = action.payload.availableSwipesCount;
            state.coins = action.payload.coins;
            state.rating = action.payload.rating;
            state.usedSwipesCount = action.payload.usedSwipesCount;
            state.sexFeatureAvailable = action.payload.sexFeatureAvailable;
            state.countryName = action.payload.countryName;
        },
    },
});
export var incrementSwipeCount = (_a = counterSlice.actions, _a.incrementSwipeCount), decrementSwipeCount = _a.decrementSwipeCount, incrementCoins = _a.incrementCoins, decrementCoins = _a.decrementCoins, setCoins = _a.setCoins, setSwipes = _a.setSwipes, setSwipeAdditionTimerStartingTime = _a.setSwipeAdditionTimerStartingTime, setFirst50 = _a.setFirst50, setFirst100 = _a.setFirst100, setBotName = _a.setBotName, setHasPhoto = _a.setHasPhoto, incrementUsedSwipesCount = _a.incrementUsedSwipesCount, setUsedSwipesCount = _a.setUsedSwipesCount, setSexFeatureAvailable = _a.setSexFeatureAvailable, setInterestedIn = _a.setInterestedIn, setUserData = _a.setUserData;
export default counterSlice.reducer;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Box, Skeleton } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserId from '../../../hooks/useUserId';
import { useLazyGetInboxQuery } from '../../../redux/apiSlice/queensUser.slice';
import EmptyState from './components/EmptyState';
import MatchCard from './components/MatchCard';
var Inbox = function () {
    var _a = useState([]), matches = _a[0], setMatches = _a[1];
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), hasMore = _d[0], setHasMore = _d[1];
    var observer = useRef();
    var userId = useUserId();
    var trigger = useLazyGetInboxQuery()[0];
    var t = useTranslation().t;
    var fetchPosts = function (page) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger({ userId: userId, page: page }).unwrap()];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
            }
        });
    }); };
    var loadMorePosts = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, newPosts;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, fetchPosts(page)];
                case 1:
                    data = _a.sent();
                    newPosts = data.content;
                    if (newPosts.length === 0) {
                        setHasMore(false); // Set hasMore to false if no more posts are returned
                    }
                    else {
                        setMatches(function (prevPosts) { return __spreadArray(__spreadArray([], prevPosts, true), newPosts, true); });
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [page]);
    useEffect(function () {
        if (hasMore) {
            loadMorePosts();
        }
    }, [loadMorePosts, hasMore]);
    var lastPostElementRef = useCallback(function (node) {
        if (loading || !hasMore)
            return;
        //@ts-ignore
        if (observer.current)
            observer.current.disconnect();
        //@ts-ignore
        observer.current = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting) {
                setPage(function (prevPage) { return prevPage + 1; });
            }
        });
        //@ts-ignore
        if (node)
            observer.current.observe(node);
    }, [loading, hasMore]);
    if (!matches.length && !hasMore) {
        return React.createElement(EmptyState, { title: t('inboxEmptyTitle') });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: 'flex', flexWrap: 'wrap', width: '100%', gap: 2, justifyContent: 'space-between' },
            matches.map(function (i, index) { return (React.createElement("div", { ref: matches.length === index + 2 ? lastPostElementRef : null, style: { width: '46.5%' }, key: index },
                React.createElement(MatchCard, { name: i.actor.name, username: i.actor.username, actiontypes: i.actionType, profilePicture: i.actor.profilePicture, isBlocked: !i.isUnblur, id: i.id }))); }),
            loading &&
                Array.from(Array(4).keys()).map(function () { return (React.createElement(Skeleton, { variant: 'rounded', width: '46.5%', height: 260, sx: { borderRadius: 2 }, component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 } })); }))));
};
export default Inbox;

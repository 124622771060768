import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../App.css';
import icon from '../../assets/arrow-svg.svg';
function CustomSelect(_a) {
    var onChange = _a.onChange, title = _a.title, options = _a.options, defaultValue = _a.defaultValue;
    var _b = useState(defaultValue || ''), value = _b[0], setValue = _b[1];
    var _c = useState(!defaultValue), isPlaceholder = _c[0], setIsPlaceholder = _c[1];
    var _d = useState(false), selectDisabled = _d[0], setSelectDisabled = _d[1];
    useEffect(function () {
        if (value === '') {
            setIsPlaceholder(true);
        }
        else {
            setIsPlaceholder(false);
        }
    }, [value]);
    var handleFocus = function () {
        if (isPlaceholder) {
            setValue('');
            setIsPlaceholder(false);
        }
    };
    var handleBlur = function () {
        if (value === '' || value === 'Select') {
            setValue('Select');
            setIsPlaceholder(true);
        }
    };
    var handleChange = function (e) {
        var newValue = e.target.value;
        setValue(newValue);
        onChange(newValue);
        if (newValue !== 'Select') {
            setSelectDisabled(true);
        }
    };
    var t = useTranslation().t;
    return (React.createElement("div", { className: 'custom-select-input' },
        React.createElement("label", { className: 'label sf-pro fs-16' }, title),
        React.createElement("select", { value: isPlaceholder ? 'Select' : value, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, className: (isPlaceholder ? 'placeholder' : '') + ' fs-16 sf-pro-semibold' },
            !defaultValue && (React.createElement("option", { value: 'Select', className: 'label sf-pro color-primary fs-16', disabled: selectDisabled }, t('select'))),
            options.map(function (opt) { return (React.createElement("option", { key: opt.value, value: opt.value, className: 'w-500 sf-pro' }, t(opt.label))); })),
        React.createElement("img", { src: icon, alt: '', className: 'icon' })));
}
export default CustomSelect;

import * as React from 'react';
var getOnLineStatus = function () {
    return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;
};
export var useNavigatorOnLine = function () {
    var _a = React.useState(getOnLineStatus()), status = _a[0], setStatus = _a[1];
    var setOnline = function () { return setStatus(true); };
    var setOffline = function () { return setStatus(false); };
    React.useEffect(function () {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);
        return function () {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);
    return status;
};

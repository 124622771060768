var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import Resizer from 'react-image-file-resizer';
import WebApp from '@twa-dev/sdk';
import tzLookup from 'tz-lookup';
import * as Sentry from '@sentry/react';
import imageResize from 'image-resize';
//146015
//5120543283  37411851
//WebApp.initDataUnsafe.user?.id
export var userId = (_a = WebApp.initDataUnsafe.user) === null || _a === void 0 ? void 0 : _a.id;
export var hashPassword = function (id) {
    return 'hash';
};
export var determineLanguage = function () {
    var _a;
    var supportedLanguages = ['en', 'ru', 'fr', 'pt-br', 'es'];
    var language = 'en';
    if (((_a = WebApp.initDataUnsafe.user) === null || _a === void 0 ? void 0 : _a.language_code) &&
        supportedLanguages.includes(WebApp.initDataUnsafe.user.language_code)) {
        language = WebApp.initDataUnsafe.user.language_code;
    }
    return language.replace('-', '_').toUpperCase();
};
export var resizeFile = function (file) {
    return new Promise(function (resolve, reject) {
        try {
            Resizer.imageFileResizer(file, 1280, 1280, 'JPEG', 80, 0, function (uri) {
                resolve(uri);
            }, 'blob');
        }
        catch (error) {
            Sentry.captureException(new Error('Image resize failed'), {
                extra: {
                    fileName: file.name,
                    fileSize: file.size,
                    fileType: file.type,
                    errorMessage: error.message || 'Unknown error',
                },
            });
            console.error('Failed to resize image:', error);
            reject(error);
        }
    });
};
export var imageResizer = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, imageResize(file, {
                        format: 'webp',
                        width: 1280,
                        height: 1280,
                        outputType: 'blob',
                        reSample: 4,
                        quality: 0.8,
                    })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                error_1 = _a.sent();
                console.error('Error resizing image:', JSON.stringify(error_1));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var abbreviateNumber = function (value) {
    var newValue = value;
    var suffixes = ['', 'K', 'M', 'B', 'T'];
    var suffixNum = 0;
    if (value < 10000) {
        var roundedValue = Math.round(value * 100) / 100;
        var displayValue = roundedValue % 1 === 0
            ? roundedValue.toString()
            : roundedValue.toFixed(1);
        return displayValue;
    }
    while (newValue >= 1000 && suffixNum < suffixes.length - 1) {
        newValue /= 1000;
        suffixNum++;
    }
    var decimalIndex = newValue.toString().indexOf('.');
    if (decimalIndex !== -1) {
        newValue = newValue.toString().slice(0, decimalIndex + 2);
    }
    newValue = parseFloat(newValue);
    newValue = newValue.toString().replace(/\.0$/, '');
    newValue += suffixes[suffixNum];
    return newValue;
};
export var formatDate = function (date) {
    var day = date.date();
    var suffix = ['th', 'st', 'nd', 'rd'][day % 10 > 3 ? 0 : (day % 100) - (day % 10) !== 10 ? day % 10 : 0];
    return date.format("D[".concat(suffix, "] MMM YYYY"));
};
export var getAge = function (birthDate) {
    var birthdate = new Date(birthDate);
    var today = new Date();
    var age = today.getFullYear() - birthdate.getFullYear();
    var hasHadBirthdayThisYear = today.getMonth() > birthdate.getMonth() ||
        (today.getMonth() === birthdate.getMonth() &&
            today.getDate() >= birthdate.getDate());
    if (!hasHadBirthdayThisYear) {
        age -= 1;
    }
    return age;
};
export var redirectToSocialMedia = function (platform, input) {
    var trimmedInput = input.trim();
    var url = '';
    if (platform === 'tiktok') {
        if (/^https?:\/\/(?:www\.)?tiktok\.com\/@(.*)$/.test(trimmedInput)) {
            var match = trimmedInput.match(/^https?:\/\/(?:www\.)?tiktok\.com\/@(.*)$/);
            if (match) {
                url = "https://www.tiktok.com/@".concat(match[1]);
            }
        }
        else {
            var username = trimmedInput.includes('@')
                ? trimmedInput.replace('@', '')
                : trimmedInput;
            url = "https://www.tiktok.com/@".concat(username);
        }
    }
    else if (platform === 'instagram') {
        if (/^https?:\/\/(?:www\.)?instagram\.com\/(.*)$/.test(trimmedInput)) {
            var match = trimmedInput.match(/^https?:\/\/(?:www\.)?instagram\.com\/(.*)$/);
            if (match) {
                url = "https://www.instagram.com/".concat(match[1]);
            }
        }
        else {
            url = "https://www.instagram.com/".concat(trimmedInput);
        }
    }
    else {
        throw new Error('Invalid platform specified');
    }
    WebApp.openLink(url);
};
export var replaceToCDN = function (url) {
    if (!url)
        return '';
    return url
        .replace('322bc7db-lovecoin.s3.timeweb.cloud', 's3.queensdating.com')
        .replace('s3.timeweb.cloud/322bc7db-lovecoin', 's3.queensdating.com');
};
export var fetchTimezone = function (latitude, longitude) {
    return tzLookup(latitude, longitude);
};

import { EReactionType, EValue } from '../types/genral.types';
import { EActionType } from '../types/queens.types';
import aquarius from '../assets/aquarius.svg';
import aries from '../assets/aries.svg';
import cancer from '../assets/cancer.svg';
import capricorn from '../assets/capricorn.svg';
import gemini from '../assets/gemini.svg';
import leo from '../assets/leo.svg';
import libra from '../assets/libra.svg';
import pisces from '../assets/pisces.svg';
import sagittarius from '../assets/sagittarius.svg';
import scorpio from '../assets/scorpion.svg';
import taurus from '../assets/taurus.svg';
import virgo from '../assets/virgo.svg';
export var zodiacSigns = {
    aquarius: aquarius,
    aries: aries,
    cancer: cancer,
    capricorn: capricorn,
    gemini: gemini,
    leo: leo,
    libra: libra,
    pisces: pisces,
    sagittarius: sagittarius,
    scorpio: scorpio,
    taurus: taurus,
    virgo: virgo,
};
export var reactions = [
    {
        icon: '👑',
        label: '1',
        type: EValue.DIMOND,
        reactionType: EReactionType.LIKE,
        actionType: EActionType.CROWN,
    },
    {
        icon: '🧡',
        label: '10',
        type: EValue.ENERGY,
        reactionType: EReactionType.LIKE,
        actionType: EActionType.LIKE,
    },
    {
        icon: '🔥️',
        label: '10',
        type: EValue.ENERGY,
        reactionType: EReactionType.LIKE,
        actionType: EActionType.FIRE,
    },
    {
        icon: '😀',
        label: '1',
        type: EValue.ENERGY,
        reactionType: EReactionType.DISLIKE,
        actionType: EActionType.LAUGH,
    },
    {
        icon: '🤝',
        label: '1',
        type: EValue.ENERGY,
        reactionType: EReactionType.DISLIKE,
        actionType: EActionType.HANDSHAKE,
    },
];
export var genders = [
    { label: 'male', value: 'MALE' },
    { label: 'female', value: 'FEMALE' },
];
//  { label: 'nonBinary', value: 'NON_BINARY' },
export var ageRange = [
    { label: '18 - 25', value: '18-25' },
    { label: '26 - 33', value: '26-33' },
    { label: '34 - 41', value: '34-41' },
    { label: '42+', value: '42-100' },
];
export var lookingForGenders = [
    { label: 'male', value: 'MALE' },
    { label: 'female', value: 'FEMALE' },
];
export var actionType = {
    LIKE: '🧡',
    CROWN: '👑',
    FIRE: '🔥️',
    LAUGH: '😀',
    HANDSHAKE: '🤝',
};
export var popularLocations = [
    {
        place_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
        structured_formatting: {
            main_text: 'San Francisco',
            secondary_text: 'CA, USA',
        },
    },
    {
        place_id: 'ChIJOwg_06VPwokRYv534QaPC8g',
        structured_formatting: {
            main_text: 'New York',
            secondary_text: 'NY, USA',
        },
    },
    {
        place_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI',
        structured_formatting: {
            main_text: 'London',
            secondary_text: 'UK',
        },
    },
    {
        place_id: 'ChIJE9on3F3HwoAR9AhGJW_fL-I',
        structured_formatting: {
            main_text: 'Los Angeles',
            secondary_text: 'CA, USA',
        },
    },
];

import { Box, Tab, Tabs } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Inbox from './Inbox';
import Mutuals from './Mutuals';
var Matches = function () {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var t = useTranslation().t;
    return (React.createElement(Box, { sx: {
            width: '100%',
        } },
        React.createElement(Box, { sx: {
                justifyContent: 'center !important',
                display: 'flex',
                width: '100%',
                background: '#E9DED9',
                borderRadius: '60px',
                padding: '2px',
            } },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": 'earn', sx: { width: '100%' } },
                React.createElement(Tab, { disableRipple: true, label: t('mutual'), sx: {
                        '&.Mui-selected': { background: 'white', borderRadius: '60px' },
                        width: '50%',
                    } }),
                React.createElement(Tab, { disableRipple: true, label: t('inbox'), sx: {
                        '&.Mui-selected': { background: 'white', borderRadius: '60px' },
                        width: '50%',
                    } }))),
        React.createElement(Box, { pt: 2, sx: {
                height: 'calc(100vh - 220px)',
                overflowY: 'scroll',
                background: '#F2EBEA',
            } },
            value === 0 && (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2, component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 } },
                React.createElement(Mutuals, null))),
            value === 1 && (React.createElement(Box, { component: motion.div },
                React.createElement(Inbox, null))))));
};
export default Matches;

import React, { useEffect, useRef } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import cardsInactive from '../../assets/cards-inactive.svg';
import cardsActive from '../../assets/cards-active.svg';
import earnInactive from '../../assets/earn-inactive.svg';
import earnActive from '../../assets/earn-active.svg';
import detailsInctive from '../../assets/details-inactive.svg';
import chatsInactive from '../../assets/chats-inactive.svg';
import chatsActive from '../../assets/chat-active.svg';
import detailsActive from '../../assets/details-active.svg';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router';
import { Badge } from '@mui/material';
import MiningBar from '../miningBar';
import { useDispatch, useSelector } from 'react-redux';
import { useSeeMatchesMutation } from '../../redux/apiSlice/queensUser.slice';
import useUserId from '../../hooks/useUserId';
import { updateUserData } from '../../redux/apiSlice/userInfo.slice';
import { motion } from 'framer-motion';
export default function SimpleBottomNavigation() {
    var _a = React.useState(''), activePage = _a[0], setActivePage = _a[1];
    var containerRef = useRef(null);
    var _b = useSelector(function (state) { return state.userInfo.userData; }), unSeenIncomingReactionsCount = _b.unSeenIncomingReactionsCount, unSeenMatchCount = _b.unSeenMatchCount;
    var dispatch = useDispatch();
    var userId = useUserId();
    var navigate = useNavigate();
    var see = useSeeMatchesMutation()[0];
    var location = useLocation();
    useEffect(function () {
        setActivePage(location.pathname);
    }, [location]);
    var show = ['/earn', '/matches'].includes(activePage);
    return (React.createElement(Paper, { component: motion.div, initial: { opacity: 0, backgroundColor: '#f2ebea' }, animate: { opacity: 1, backgroundColor: show ? '#2E0909' : '#f2ebea' }, exit: { opacity: 0, backgroundColor: '#f2ebea' }, transition: { duration: 0.2 }, className: 'navigation-bar-wrapper', elevation: 3, ref: containerRef, sx: {
            backgroundColor: '#f2ebea',
        } },
        React.createElement(MiningBar, { containerRef: containerRef }),
        React.createElement(BottomNavigation, { className: 'navigation-bar', value: activePage, onChange: function (event, newValue) {
                setActivePage(newValue);
            } },
            React.createElement(BottomNavigationAction, { sx: { minWidth: 'unset', paddingX: '6px' }, onClick: function () { return navigate('/'); }, icon: React.createElement("div", { style: { padding: 3, borderRadius: '50%' } },
                    React.createElement("img", { src: activePage !== '/' ? cardsInactive : cardsActive })) }),
            React.createElement(BottomNavigationAction, { sx: { minWidth: 'unset', paddingX: '6px' }, onClick: function () { return navigate('/earn'); }, icon: React.createElement("div", { style: { padding: 3, borderRadius: '50%' } },
                    React.createElement("img", { src: activePage !== '/earn' ? earnInactive : earnActive })) }),
            React.createElement(BottomNavigationAction, { sx: { minWidth: 'unset', paddingX: '6px' }, onClick: function () {
                    navigate('/matches');
                    if (!!unSeenIncomingReactionsCount || !!unSeenMatchCount) {
                        see({ userId: userId });
                        dispatch(updateUserData({
                            unSeenIncomingReactionsCount: 0,
                            unSeenMatchCount: 0,
                        }));
                    }
                }, icon: React.createElement(Badge, { color: 'error', badgeContent: unSeenIncomingReactionsCount + unSeenMatchCount, max: 99, className: 'color-white', sx: {
                        '& .MuiBadge-badge': {
                            background: ' linear-gradient(180deg, #FFADD4 0%, #FEA5A8 100%)', // Custom color
                            fontFamily: "'SF Pro', sans-serif",
                            fontWeight: 700,
                            fontSize: 10,
                        },
                    } },
                    React.createElement("div", { style: { padding: 3, borderRadius: '50%' } },
                        React.createElement("img", { src: activePage !== '/matches' ? chatsInactive : chatsActive }))) }),
            React.createElement(BottomNavigationAction, { sx: { minWidth: 'unset', paddingX: '6px' }, onClick: function () { return navigate('/user'); }, icon: React.createElement("div", { style: { padding: 3, borderRadius: '50%' } },
                    React.createElement("img", { src: activePage !== '/user' ? detailsInctive : detailsActive })) }))));
}

var _a;
import instagram from '../../../assets/instagram.png';
import telegram from '../../../assets/telegram.png';
import twitter from '../../../assets/twitter.png';
import { ETaskType } from '../../../types/queens.types';
export var subscriptionImageMap = (_a = {},
    _a[ETaskType.TELEGRAM_CHANEL_SUBSCRIPTION] = telegram,
    _a[ETaskType.INSTAGRAM_ACCOUNT_SUBSCRIPTION] = instagram,
    _a[ETaskType.TWITTER_ACCOUNT_SUBSCRIPTION] = twitter,
    _a[ETaskType.OTHER] = telegram,
    _a);

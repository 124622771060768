import { Grid, Typography } from '@mui/material';
import React from 'react';
var StepHeader = function (_a) {
    var title = _a.title, step = _a.step, endStep = _a.endStep, secondaryText = _a.secondaryText;
    return (React.createElement(Grid, { container: true, justifyContent: 'space-between', sx: {
            px: '8px',
        } },
        React.createElement(Grid, { item: true, xs: 6 },
            React.createElement(Typography, { className: 'karla onboarding-step-title' },
                title,
                ' ',
                secondaryText && (React.createElement("span", { style: { WebkitTextFillColor: '#7A7A7A' } },
                    "(",
                    secondaryText,
                    ")")))),
        React.createElement(Grid, { item: true, xs: 4 },
            React.createElement(Typography, { className: 'karla onboarding-step-numeration', sx: {
                    WebkitTextFillColor: '#AFA9A7',
                } },
                step,
                "/",
                endStep || '4'))));
};
export default StepHeader;

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, List, ListItem, ListItemText, Container, Box, Divider, } from '@mui/material';
import WebApp from '@twa-dev/sdk';
import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';
var FAQ = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var handlerClick = function () {
        navigate('/user');
    };
    useEffect(function () {
        WebApp.BackButton.onClick(handlerClick);
        return function () { return WebApp.BackButton.offClick(handlerClick); };
    }, []);
    return (React.createElement(Container, { sx: {
            pb: '50px',
            px: 0,
        }, component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 } },
        React.createElement(Box, { my: 4 },
            React.createElement(Typography, { sx: { fontSize: '20px !important' }, className: 'karla', gutterBottom: true }, t('faq.title', 'FAQ')),
            React.createElement(List, null, __spreadArray([], Array(11), true).map(function (_, index) {
                var questionKey = "question_".concat(index + 1);
                var answerKey = "question_".concat(index + 1, "_answer");
                if (index === 1) {
                    return (React.createElement(React.Fragment, { key: index },
                        React.createElement(ListItem, null,
                            React.createElement(ListItemText, { primary: React.createElement(Typography, { className: 'karla' }, t(questionKey)), secondary: React.createElement(React.Fragment, null,
                                    React.createElement(Typography, { className: 'karla' }, t('question_2_answer_1')),
                                    React.createElement("ul", null,
                                        React.createElement("li", null,
                                            React.createElement(Typography, { className: 'karla' }, t('question_2_answer_2'))),
                                        React.createElement("li", null,
                                            React.createElement(Typography, { className: 'karla' }, t('question_2_answer_3'))),
                                        React.createElement("li", null,
                                            React.createElement(Typography, { className: 'karla' }, t('question_2_answer_4'))),
                                        React.createElement("li", null,
                                            React.createElement(Typography, { className: 'karla' }, t('question_2_answer_5'))),
                                        React.createElement("li", null,
                                            React.createElement(Typography, { className: 'karla' }, t('question_2_answer_6')))),
                                    React.createElement(Typography, { className: 'karla' },
                                        ' ',
                                        t('question_2_answer_7'),
                                        ' ')) })),
                        React.createElement(Divider, null)));
                }
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement(ListItem, null,
                        React.createElement(ListItemText, { primary: React.createElement(Typography, { className: 'karla' }, t(questionKey)), secondary: React.createElement(Typography, { className: 'karla' }, t(answerKey)) })),
                    React.createElement(Divider, null)));
            })))));
};
export default FAQ;

var _a;
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userApi } from './apiSlice/user.slice';
import counterReducer from './apiSlice/metrics.slice';
import userInfoReducer from './apiSlice/userInfo.slice';
import { thirdPartApi } from './apiSlice/thirdPart.slice';
import { queensUserApi } from './apiSlice/queensUser.slice';
import { storageApi } from './apiSlice/storage.slice';
import { tasksApi } from './apiSlice/tasks.slice';
import { loginApi } from './apiSlice/login.slice';
import { tgServiceApi } from './apiSlice/tgService.slice';
export var store = configureStore({
    reducer: (_a = {},
        _a[userApi.reducerPath] = userApi.reducer,
        _a[thirdPartApi.reducerPath] = thirdPartApi.reducer,
        _a[queensUserApi.reducerPath] = queensUserApi.reducer,
        _a[storageApi.reducerPath] = storageApi.reducer,
        _a[tasksApi.reducerPath] = tasksApi.reducer,
        _a[loginApi.reducerPath] = loginApi.reducer,
        _a[tgServiceApi.reducerPath] = tgServiceApi.reducer,
        _a.counter = counterReducer,
        _a.userInfo = userInfoReducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware()
            .concat(userApi.middleware)
            .concat(thirdPartApi.middleware)
            .concat(queensUserApi.middleware)
            .concat(storageApi.middleware)
            .concat(loginApi.middleware)
            .concat(tasksApi.middleware)
            .concat(tgServiceApi.middleware);
    },
});
setupListeners(store.dispatch);

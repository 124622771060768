import dayjs from 'dayjs';
export function convertOnboardingQuestions(onboardingQuestions) {
    var _a, _b;
    var date = dayjs(onboardingQuestions.birthDate);
    return {
        name: onboardingQuestions.name,
        about: onboardingQuestions.aboutYou,
        birthDate: date.format('YYYY-MM-DD'),
        gender: onboardingQuestions.gender,
        location: {
            name: onboardingQuestions.location.cityName,
            countryName: onboardingQuestions.location.countryName,
            longitute: onboardingQuestions.location.longitude,
            latitude: onboardingQuestions.location.latitude,
            zoneId: onboardingQuestions.location.zoneId,
        },
        previousCityName: onboardingQuestions.previousCity,
        lookingForGender: onboardingQuestions.lookingForGender,
        lookingForAgeFrom: onboardingQuestions.lookingForAge.split('-')[0],
        lookingForAgeTo: onboardingQuestions.lookingForAge.split('-')[1],
        socialMediaLinks: onboardingQuestions.instagram || onboardingQuestions.tiktok
            ? {
                INSTAGRAM: (_a = onboardingQuestions.instagram) === null || _a === void 0 ? void 0 : _a.trim(),
                TIK_TOK: (_b = onboardingQuestions.tiktok) === null || _b === void 0 ? void 0 : _b.trim(),
            }
            : null,
        primaryProfilePicture: onboardingQuestions.primaryProfilePicture,
        pictureGallery: onboardingQuestions.pictureGallery,
    };
}
export function convertEditProfile(onboardingQuestions) {
    var date = dayjs(onboardingQuestions.birthDate);
    return {
        name: onboardingQuestions.name,
        about: onboardingQuestions.aboutYou,
        birthDate: onboardingQuestions.birthDate ? date.format('YYYY-MM-DD') : null,
        gender: onboardingQuestions.gender,
        location: onboardingQuestions.location.cityName
            ? {
                name: onboardingQuestions.location.cityName,
                countryName: onboardingQuestions.location.countryName,
                longitute: onboardingQuestions.location.longitude,
                latitude: onboardingQuestions.location.latitude,
                zoneId: onboardingQuestions.location.zoneId,
            }
            : undefined,
        previousCityName: onboardingQuestions.previousCity,
        socialMediaLinks: onboardingQuestions.instagram || onboardingQuestions.tiktok
            ? {
                INSTAGRAM: onboardingQuestions.instagram,
                TIK_TOK: onboardingQuestions.tiktok,
            }
            : null,
        primaryProfilePicture: onboardingQuestions.primaryProfilePicture,
        pictureGallery: onboardingQuestions.pictureGallery.length
            ? onboardingQuestions.pictureGallery
            : undefined,
        lookingForGender: onboardingQuestions.lookingForGender,
        lookingForAgeFrom: onboardingQuestions.lookingForAge &&
            onboardingQuestions.lookingForAge.split('-')[0],
        lookingForAgeTo: onboardingQuestions.lookingForAge &&
            onboardingQuestions.lookingForAge.split('-')[1],
    };
}

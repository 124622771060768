import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Onboarding from '.';
import CustomSelect from '../../../components/customSelect';
import { ageRange, lookingForGenders } from '../../../data/general.data';
import { updateOnboardingQuestions } from '../../../redux/apiSlice/userInfo.slice';
import { store } from '../../../redux/store';
import { EOnboardingSteps } from '../../../types/queens.types';
import StepHeader from './components/StepHeader';
var LookingFor = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), interestedInAgeEnd = _a.interestedInAgeEnd, interestedInAgeStart = _a.interestedInAgeStart, interestedIn = _a.interestedIn;
    var _b = useState(!!interestedIn), lookingForGender = _b[0], setLookingForGender = _b[1];
    var _c = useState(!!interestedInAgeStart), lookingForAge = _c[0], setLookingForAge = _c[1];
    var t = useTranslation().t;
    var pereferedGender = function (lookingForGender) {
        setLookingForGender(!!lookingForGender);
        store.dispatch(updateOnboardingQuestions({ lookingForGender: lookingForGender }));
    };
    var preferedAge = function (lookingForAge) {
        setLookingForAge(!!lookingForAge);
        store.dispatch(updateOnboardingQuestions({ lookingForAge: lookingForAge }));
    };
    useEffect(function () {
        store.dispatch(updateOnboardingQuestions({
            lookingForAge: "".concat(interestedInAgeStart, "-").concat(interestedInAgeEnd),
            lookingForGender: interestedIn,
        }));
    }, []);
    return (React.createElement(Onboarding, { nextPage: 'socials', disabled: !lookingForAge || !lookingForGender, step: EOnboardingSteps.ONBOARD_LOOKING_FOR },
        React.createElement(Stack, { gap: 2, className: 'w-full' },
            React.createElement(StepHeader, { title: t('looking_for_title'), step: 3 }),
            React.createElement(CustomSelect, { onChange: pereferedGender, title: t('gender'), options: lookingForGenders.map(function (g) { return ({
                    value: g.value,
                    label: t(g.label),
                }); }), defaultValue: interestedIn }),
            React.createElement(CustomSelect, { onChange: preferedAge, title: t('age_range'), options: ageRange, defaultValue: interestedInAgeStart
                    ? "".concat(interestedInAgeStart, "-").concat(interestedInAgeEnd)
                    : undefined }))));
};
export default LookingFor;

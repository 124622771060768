var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    onboardingQuestions: {
        name: undefined,
        aboutYou: undefined,
        birthDate: undefined,
        gender: undefined,
        location: {
            cityName: undefined,
            countryName: undefined,
            latitude: null,
            longitude: null,
            zoneId: '',
        },
        previousCity: undefined,
        lookingForGender: undefined,
        lookingForAge: undefined,
        instagram: undefined,
        tiktok: undefined,
        primaryProfilePicture: undefined,
    },
    userData: {
        diamond: 0,
        energy: 1,
        id: undefined,
        existsUnSeenMatch: null,
        canCollectPassiveMaining: false,
        passiveMainingStartTime: null,
        onboarded: null,
        passiveMiningEnergyEarnAmount: 0,
        pictureGallery: [],
    },
    cards: [],
};
var userSlice = createSlice({
    name: 'userInfo',
    initialState: initialState,
    reducers: {
        updateOnboardingQuestions: function (state, action) {
            state.onboardingQuestions = __assign(__assign({}, state.onboardingQuestions), action.payload);
        },
        updateUserData: function (state, action) {
            state.userData = __assign(__assign({}, state.userData), action.payload);
        },
        decrementEnergy: function (state, action) {
            state.userData.energy -= action.payload;
        },
        decrementDimond: function (state, action) {
            state.userData.diamond -= action.payload;
        },
        incrementDimond: function (state, action) {
            state.userData.diamond += action.payload;
        },
        resetClaimStats: function (state) {
            state.userData.canCollectPassiveMaining = false;
            state.userData.passiveMainingStartTime = null;
        },
        startClaim: function (state) {
            //@ts-ignore
            state.userData.passiveMainingStartTime = Date.now();
        },
        addSwipedUserId: function (state, action) {
            //@ts-ignore
            state.cards = action.payload;
        },
        addPassiveMiningAmmount: function (state, action) {
            state.userData.energy = state.userData.energy + action.payload;
        },
        addImageToGallery: function (state, action) {
            //@ts-ignore
            state.userData.pictureGallery = __spreadArray(__spreadArray([], state.userData.pictureGallery, true), [
                action.payload,
            ], false);
        },
        removeImageFromGallery: function (state, action) {
            state.userData.pictureGallery = state.userData.pictureGallery.filter(function (img) { return img != action.payload; });
        },
        setPrimaryPhoto: function (state, action) {
            state.onboardingQuestions = __assign(__assign({}, state.onboardingQuestions), { primaryProfilePicture: action.payload });
        },
    },
});
export var updateOnboardingQuestions = (_a = userSlice.actions, _a.updateOnboardingQuestions), updateUserData = _a.updateUserData, decrementDimond = _a.decrementDimond, decrementEnergy = _a.decrementEnergy, resetClaimStats = _a.resetClaimStats, addSwipedUserId = _a.addSwipedUserId, addPassiveMiningAmmount = _a.addPassiveMiningAmmount, startClaim = _a.startClaim, incrementDimond = _a.incrementDimond, removeImageFromGallery = _a.removeImageFromGallery, addImageToGallery = _a.addImageToGallery, setPrimaryPhoto = _a.setPrimaryPhoto;
export default userSlice.reducer;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import useUserId from '../../../hooks/useUserId';
import { useUnPauseProfileMutation } from '../../../redux/apiSlice/queensUser.slice';
import { getAge } from '../../../utils/general.utils';
var PausedProfile = function () {
    var userId = useUserId();
    var _a = useSelector(function (state) { return state.userInfo.userData; }), name = _a.name, birthDate = _a.birthDate, primaryProfilePicture = _a.primaryProfilePicture;
    var navigate = useNavigate();
    var _b = useUnPauseProfileMutation(), unpause = _b[0], isLoading = _b[1].isLoading;
    var t = useTranslation().t;
    var handleUnpauseProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, unpause({ userId: userId }).unwrap()];
                case 1:
                    _a.sent();
                    // showToast(t('profileUnpaused'));
                    navigate('/');
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    showToast('Something went wrong');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var showToast = function (message) {
        toast(message, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false,
            theme: 'dark',
        });
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3, sx: { height: '90svh' }, justifyContent: 'center' },
            React.createElement(Avatar, { alt: name, src: primaryProfilePicture, sx: { width: 117, height: 117 } }),
            React.createElement(Box, null,
                React.createElement(Typography, { className: 'reckless-32' },
                    name,
                    " ",
                    React.createElement("span", { className: 'reckless-light' }, getAge(birthDate))),
                React.createElement(Typography, { variant: 'body1', className: 'karla w-500 fs-18', sx: {
                        lineHeight: '21.04px',
                        letterSpacing: '-0.41px',
                    } }, t('profilePaused')))),
        React.createElement(LoadingButton, { onClick: handleUnpauseProfile, fullWidth: true, variant: 'contained', className: 'register-btn register-btn-hover sf-pro-bold', sx: {
                borderRadius: '20px',
                backgroundColor: 'black',
                color: 'white',
                height: '56px',
                fontSize: '17px',
                lineHeight: '20.29px',
            } }, isLoading ? (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'white' } })) : (t('unpause')))));
};
export default PausedProfile;

import React, { useEffect, useState } from 'react';
import icon from '../../assets/arrow-svg.svg';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatDate } from '../../utils/general.utils';
import dayjs from 'dayjs';
import { DatePicker } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
var CustomDatePicker = function (_a) {
    var onChange = _a.onChange, defaultValue = _a.defaultValue;
    var _b = useState(null), pickedDate = _b[0], setPickedDate = _b[1];
    var _c = useState(false), isEditMode = _c[0], setIsEditMode = _c[1];
    var initialDate = dayjs().subtract(18, 'year').endOf('year').toDate();
    var t = useTranslation().t;
    useEffect(function () {
        if (defaultValue) {
            var date = new Date(defaultValue);
            setPickedDate(date);
        }
    }, [defaultValue]);
    var monthNames = [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
    ];
    return (React.createElement("div", { className: 'custom-date-input' },
        React.createElement("label", { style: { marginRight: '10px', fontWeight: 'bold' }, className: 'sf-pro fs-16' }, t('date_of_birth')),
        React.createElement("label", { style: { paddingRight: 'calc(4px + 16px)', fontWeight: 'bold' }, className: 'sf-pro color-primary fs-16', onClick: function () { return setIsEditMode(true); } }, (pickedDate && formatDate(dayjs(pickedDate))) ||
            t('date_of_birth_choose_select')),
        React.createElement(DatePicker, { visible: isEditMode, onClose: function () {
                setIsEditMode(false);
            }, value: pickedDate, min: dayjs('1900-01-01').toDate(), max: initialDate, precision: 'day', confirmText: t('confirm'), cancelText: t('cancel'), renderLabel: function (type, data) {
                if (type === 'month') {
                    return monthNames[data - 1];
                }
                return data;
            }, onConfirm: function (e) {
                if (e) {
                    //@ts-ignore
                    setPickedDate(e);
                    onChange(dayjs(e).format('MM/DD/YYYY'));
                }
                setIsEditMode(false);
            } }),
        React.createElement("img", { src: icon, alt: '', className: 'icon' })));
};
export default CustomDatePicker;

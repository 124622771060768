// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './lang/en';
import { ru } from './lang/converted_ru';
import { fr } from './lang/converted_fr';
import { es } from './lang/converted_es';
import { pt } from './lang/converted_pt';
i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en,
        },
        ru: {
            translation: ru,
        },
        fr: {
            translation: fr,
        },
        es: {
            translation: es,
        },
        'pt-br': {
            translation: pt,
        },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;

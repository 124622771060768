import { Box, Skeleton } from '@mui/material';
import React from 'react';
var LoadingState = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                position: 'relative',
                paddingTop: '20px',
                height: 'calc(100vh - 105px)',
                overflowY: 'hidden',
                background: '#F2EBEA',
            } },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'candidates-tab-content' },
                    React.createElement(Box, { sx: { marginBottom: '20px' } },
                        React.createElement(Skeleton, { variant: 'text', width: '60%', height: '80px' }),
                        React.createElement(Skeleton, { variant: 'text', width: '50%', height: '40px' })),
                    React.createElement(Box, { position: 'relative', width: '100%', height: 'auto' },
                        React.createElement(Skeleton, { variant: 'rectangular', height: '470px', sx: { borderRadius: 3 } })),
                    React.createElement(Box, { sx: { marginBottom: '20px', marginTop: '20px' } },
                        React.createElement(Skeleton, { variant: 'text', width: '50%', height: '40px' }),
                        React.createElement(Skeleton, { variant: 'text', width: '60%', height: '40px' })),
                    React.createElement(Box, { position: 'relative', width: '100%', height: 'auto' },
                        React.createElement(Skeleton, { variant: 'rectangular', height: '470px', sx: { borderRadius: 3 } })))))));
};
export default LoadingState;

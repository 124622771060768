import { Box, Button, DialogTitle, IconButton, SwipeableDrawer, Typography, } from '@mui/material';
import React, { useState } from 'react';
import InviteDrawerContent from './InviteDrawerContent';
import closeSvg from '../../../../assets/close_round.svg';
import { useTranslation } from 'react-i18next';
var InviteFriend = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var t = useTranslation().t;
    var iOS = typeof navigator !== 'undefined' &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (React.createElement(Box, { sx: {
            borderRadius: '10px',
            display: 'flex',
            py: '12px',
            px: '16pt',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'space-between',
            background: 'linear-gradient(90deg, #2B0505 0%, #612A2A 100%)',
        } },
        React.createElement(Box, { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
            React.createElement(Typography, { className: 'color-white sf-pro-semibold', sx: { fontSize: '14px !important', mb: '5px' } }, t('invite_friend')),
            React.createElement(Typography, { className: 'color-white sf-pro-semibold', sx: {
                    fontSize: '14px !important',
                } }, "+\uD83D\uDC8E 1")),
        React.createElement(Button, { className: 'color-white sf-pro-bold chip-button', variant: 'outlined', onClick: function () { return setIsOpen(true); }, sx: {
                padding: '16px !important',
                width: 'fit-content',
                fontSize: '12px !important',
            } }, t('invite')),
        React.createElement(SwipeableDrawer, { sx: { borderRadius: '16px' }, open: isOpen, onClose: function () {
                setIsOpen(false);
            }, onOpen: function () { }, anchor: 'bottom', disableBackdropTransition: !iOS, disableDiscovery: iOS },
            React.createElement(DialogTitle, { className: 'drawerTitle' },
                React.createElement(IconButton, { onClick: function () { return setIsOpen(false); }, sx: { paddingRight: 3, paddingTop: 2 } },
                    React.createElement("img", { src: closeSvg }))),
            React.createElement(InviteDrawerContent, null))));
};
export default InviteFriend;

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useState, useEffect, useRef } from 'react';
import icon from '../../assets/arrow-svg.svg';
import GoogleMaps from '../googleLocationSearch';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
export var CountrySelect = function (_a) {
    var onChange = _a.onChange, initValue = _a.initValue, label = _a.label, onOpen = _a.onOpen, backClicked = _a.backClicked;
    var _b = useSelector(function (state) { return state.userInfo.userData; }), countryName = _b.countryName, cityName = _b.cityName;
    var _c = useState(initValue || ''), selectedCountry = _c[0], setSelectedCountry = _c[1];
    var _d = React.useState(cityName ? "".concat(cityName, ",").concat(countryName) : ''), inputValue = _d[0], setInputValue = _d[1];
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var inputElement = useRef(null);
    useEffect(function () {
        if (!backClicked) {
            setIsOpen(false);
        }
    }, [backClicked]);
    var t = useTranslation().t;
    var iOS = typeof navigator !== 'undefined' &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);
    useEffect(function () {
        if (initValue) {
            setSelectedCountry(initValue);
        }
    }, [initValue]);
    var handleLocationSelect = function (cityName, countryName, latitude, longitude, zoneId) {
        setSelectedCountry("".concat(cityName, ", ").concat(countryName));
        onChange(cityName, countryName, latitude, longitude, zoneId);
        setIsOpen(false);
        onOpen(false);
    };
    console.log(inputValue);
    return (React.createElement("div", { className: 'custom-select-input', "aria-hidden": 'false' },
        React.createElement("label", { className: 'label sf-pro fs-16' }, label),
        React.createElement("label", { className: 'label sf-pro color-primary fs-16', onClick: function () {
                setIsOpen(true);
                onOpen(true);
            }, style: { paddingRight: ' calc(4px + 16px)' } }, selectedCountry || inputValue || t('select')),
        React.createElement("img", { src: icon, alt: '', className: 'icon' }),
        React.createElement(SwipeableDrawer, { sx: {
                '& .MuiDrawer-paper': {
                    height: 'calc(var(--vh, 1vh) * 100)',
                    marginTop: '20px',
                    background: '#f2ebea',
                    borderRadius: 0,
                },
            }, open: isOpen, onClose: function () {
                setIsOpen(false);
                onOpen(false);
            }, onOpen: function () { }, anchor: 'bottom', disableBackdropTransition: !iOS, disableDiscovery: iOS },
            React.createElement(Typography, { className: 'sf-pro', sx: {
                    fontSize: '15px !important',
                    WebkitTextFillColor: '#7A7A7A',
                    mx: '30px',
                    mt: '20px',
                    mb: '10px',
                } }, t('location')),
            React.createElement("input", { id: 'input_focused', style: {
                    marginLeft: '30px',
                    marginRight: '30px',
                    marginBottom: '15px',
                }, className: 'onboarding-text-input karla', placeholder: t('city_input_title'), onChange: function (e) { return setInputValue(e.target.value); }, ref: inputElement, defaultValue: inputValue || '' }),
            React.createElement(GoogleMaps, { onChange: handleLocationSelect, inputValue: inputValue, storeValue: cityName ? "".concat(cityName, ",").concat(countryName) : undefined }))));
};

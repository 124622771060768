export var EGender;
(function (EGender) {
    EGender["MALE"] = "MALE";
    EGender["FEMALE"] = "FEMALE";
})(EGender || (EGender = {}));
export var ESwipeAction;
(function (ESwipeAction) {
    ESwipeAction["LIKE"] = "LIKE";
    ESwipeAction["DISLIKE"] = "DISLIKE";
})(ESwipeAction || (ESwipeAction = {}));
export var SwipeDirection;
(function (SwipeDirection) {
    SwipeDirection[SwipeDirection["LEFT"] = -1] = "LEFT";
    SwipeDirection[SwipeDirection["RIGHT"] = 1] = "RIGHT";
})(SwipeDirection || (SwipeDirection = {}));
export var SwipeAction;
(function (SwipeAction) {
    SwipeAction["LIKE"] = "LIKE";
    SwipeAction["DISLIKE"] = "DISLIKE";
    SwipeAction["FINISHED"] = "finished";
})(SwipeAction || (SwipeAction = {}));
export var SwipeOperation;
(function (SwipeOperation) {
    SwipeOperation["SWIPE"] = "swipe";
    SwipeOperation["CLICK"] = "click";
})(SwipeOperation || (SwipeOperation = {}));
export var ESexPreference;
(function (ESexPreference) {
    ESexPreference["WANT_SEX"] = "WANT_SEX";
    ESexPreference["DO_NOT_WANT_SEX"] = "DO_NOT_WANT_SEX";
})(ESexPreference || (ESexPreference = {}));
////////////QUEENS
export var EValue;
(function (EValue) {
    EValue["DIMOND"] = "DIMOND";
    EValue["ENERGY"] = "ENERGY";
})(EValue || (EValue = {}));
export var EReactionType;
(function (EReactionType) {
    EReactionType["LIKE"] = "LIKE";
    EReactionType["DISLIKE"] = "DISLIKE";
})(EReactionType || (EReactionType = {}));

import { Avatar, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
var EmptyState = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), name = _a.name, birthDate = _a.birthDate, primaryProfilePicture = _a.primaryProfilePicture;
    var t = useTranslation().t;
    return (React.createElement(Box, { component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 }, display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 4, justifyContent: 'center' },
        React.createElement("div", { className: 'pulsing-wrapper' },
            React.createElement(Avatar, { className: 'avatar', alt: name, src: primaryProfilePicture, sx: { width: 117, height: 117 } })),
        React.createElement(Box, null,
            React.createElement(Typography, { className: 'sf-pro-semibold', sx: {
                    fontSize: '17px !important',
                    lineHeight: '20.29px',
                    mb: '10px',
                    letterSpacing: '1px',
                } }, t('checkBackLater')),
            React.createElement(Typography, { variant: 'body1', className: 'karla w-400 fs-58', sx: {
                    lineHeight: '17.54px',
                    WebkitTextFillColor: '#7A7A7A',
                    width: '160px',
                    fontSize: '15px !important',
                } }, t('noOne')))));
};
export default EmptyState;

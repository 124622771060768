import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import Onboarding from '.';
import { updateOnboardingQuestions } from '../../../redux/apiSlice/userInfo.slice';
import { store } from '../../../redux/store';
import StepHeader from './components/StepHeader';
import instagramSvg from '../../../assets/instagram.svg';
import tiktokSvg from '../../../assets/tiktok.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EOnboardingSteps } from '../../../types/queens.types';
var Social = function () {
    var socialMediaLinks = useSelector(function (state) { return state.userInfo.userData; }).socialMediaLinks;
    var onInstchange = function (instagram) {
        store.dispatch(updateOnboardingQuestions({ instagram: instagram }));
    };
    var onTtChange = function (tiktok) {
        store.dispatch(updateOnboardingQuestions({ tiktok: tiktok }));
    };
    useEffect(function () {
        store.dispatch(updateOnboardingQuestions({
            instagram: socialMediaLinks === null || socialMediaLinks === void 0 ? void 0 : socialMediaLinks.INSTAGRAM,
            tiktok: socialMediaLinks === null || socialMediaLinks === void 0 ? void 0 : socialMediaLinks.TIK_TOK,
        }));
    }, []);
    var t = useTranslation().t;
    return (React.createElement(Onboarding, { nextPage: 'profile-photo', disabled: false, step: EOnboardingSteps.ONBOARD_SOCIAL_MEDIA },
        React.createElement(Stack, { gap: 2, className: 'w-full' },
            React.createElement(StepHeader, { title: "".concat(t('social_title')), step: 4, secondaryText: t('optional') }),
            React.createElement("input", { className: 'onboarding-text-input karla', placeholder: t('instagram_input_placeholder'), onChange: function (e) { return onInstchange(e.target.value); }, style: {
                    backgroundImage: "url(".concat(instagramSvg, ")"),
                    backgroundPosition: '20px ',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: ' 22px',
                    paddingLeft: '50px',
                }, defaultValue: socialMediaLinks === null || socialMediaLinks === void 0 ? void 0 : socialMediaLinks.INSTAGRAM }),
            React.createElement("input", { style: {
                    backgroundImage: "url(".concat(tiktokSvg, ")"),
                    backgroundPosition: '20px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: ' 22px',
                    paddingLeft: '50px',
                }, className: 'onboarding-text-input karla', placeholder: t('tiktok_input_placeholder'), onChange: function (e) { return onTtChange(e.target.value); }, defaultValue: socialMediaLinks === null || socialMediaLinks === void 0 ? void 0 : socialMediaLinks.TIK_TOK }))));
};
export default Social;

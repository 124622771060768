var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Stack, IconButton, CircularProgress, } from '@mui/material';
import { useSelector } from 'react-redux';
import WebApp from '@twa-dev/sdk';
import Confetti from 'react-confetti';
import { actionType } from '../../../data/general.data';
import { EActionType } from '../../../types/queens.types';
import crownSvg from '../../../assets/crown.svg';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import useUserId from '../../../hooks/useUserId';
import { useGoToChatMutation } from '../../../redux/apiSlice/queensUser.slice';
var MatchOverlay = function (_a) {
    // const [isCrown, setIsCrown] = useState(EActionType.CROWN == myActionType);
    var matchName = _a.matchName, matchPicture = _a.matchPicture, matchType = _a.matchType, username = _a.username, show = _a.show, handleCloseMatchScreen = _a.handleCloseMatchScreen, myActionType = _a.myActionType, targetUserId = _a.targetUserId;
    var _b = useState(EActionType.CROWN == myActionType && typeof show == 'boolean'), isActive = _b[0], setIsActive = _b[1];
    var primaryProfilePicture = useSelector(function (state) { return state.userInfo.userData; }).primaryProfilePicture;
    var _c = useState(matchPicture), matchPic = _c[0], setMatchPic = _c[1];
    useEffect(function () {
        setMatchPic(matchPicture);
    }, []);
    var isCrown = EActionType.CROWN == myActionType && typeof show == 'boolean';
    var _d = useGoToChatMutation(), goToChat = _d[0], chatLoading = _d[1].isLoading;
    var userId = useUserId();
    var handleGoToChat = function (targetUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, goToChat({
                            actingUserId: userId,
                            targetUserId: targetUserId,
                        }).unwrap()];
                case 1:
                    data = _a.sent();
                    handleCloseMatchScreen();
                    WebApp.openTelegramLink(data.chatUrl);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    showToast('Something went wrong');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var showToast = function (message) {
        toast(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            closeButton: false,
        });
    };
    return (React.createElement(Box, { className: "element ".concat(show || isActive ? 'visible' : '', " ").concat(isCrown ? 'crown-bg' : ''), sx: {
            backgroundColor: '#FDF7F2',
            borderRadius: '16px',
            p: 4,
            textAlign: 'center',
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            paddingTop: '20px',
            height: 'calc(100vh)',
            overflowY: 'scroll',
            background: '#F2EBEA',
            zIndex: '1000',
            position: 'fixed',
            width: '85%',
            overflow: 'hidden',
            top: 0,
        } },
        show && React.createElement(Confetti, { recycle: false }),
        React.createElement(Box, { position: 'relative' },
            React.createElement(Typography, { variant: 'h5', sx: {
                    fontWeight: 'bold',
                    my: 6,
                    fontSize: '32px !important',
                    lineHeight: '34.4px',
                    letterSpacing: '-0.41px',
                }, className: 'reckless' }, isCrown ? t('crownMatchTitle') : t('it_is_a_match')),
            isCrown && React.createElement("img", { src: crownSvg, className: 'cronw-img' }),
            React.createElement(Stack, { direction: 'row', justifyContent: 'center', alignItems: 'center', spacing: 2, sx: { my: 3, position: 'relative' } },
                React.createElement(Avatar, { alt: 'User 1', src: primaryProfilePicture, sx: {
                        width: 135,
                        height: 180,
                        borderRadius: '20px',
                        rotate: '-4.53deg',
                        right: '-10px',
                    } }),
                React.createElement(Avatar, { alt: 'User 2', src: matchPicture || matchPic, sx: {
                        width: 135,
                        height: 180,
                        borderRadius: '20px',
                        rotate: '5.7deg',
                        left: '-10px',
                        zIndex: '1',
                        //   top: '-5px',
                    } }),
                !isCrown && (React.createElement(Box, { sx: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        zIndex: '2',
                        bottom: '-2px',
                        margin: '0px !important',
                    } },
                    React.createElement(IconButton, { sx: {
                            background: 'white',
                            width: 32,
                            height: 32,
                            fontSize: '22px',
                            paddingBottom: '10px',
                            right: '-2px',
                        }, className: 'reactions' }, 
                    //@ts-ignore
                    actionType[myActionType]),
                    React.createElement(IconButton, { sx: {
                            background: 'white',
                            width: 32,
                            height: 32,
                            fontSize: '22px',
                            paddingBottom: '10px',
                            left: '-2px',
                        }, className: 'reactions' }, 
                    //@ts-ignore
                    actionType[matchType])))),
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'center' } },
                React.createElement(Typography, { variant: 'body1', sx: {
                        WebkitTextFillColor: isCrown
                            ? '#2B0505'
                            : 'rgba(122, 122, 122, 1)',
                        width: '200px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        mt: 5,
                        fontSize: '15px !important',
                        letterSpacing: '1px',
                    }, className: 'karla fs-15' }, isCrown
                    ? t('crownMatchInfo', { name: matchName })
                    : t('matchInfo', { name: matchName })))),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',
            } },
            React.createElement(Button, { className: 'register-btn sf-pro-semibold', sx: {
                    borderRadius: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    height: '56px',
                    fontSize: '17px',
                    lineHeight: '20.29px',
                }, fullWidth: true, onClick: function () {
                    if (username) {
                        WebApp.openTelegramLink("https://t.me/".concat(username));
                        handleCloseMatchScreen();
                    }
                    else {
                        handleGoToChat(targetUserId);
                    }
                } }, t('goToChat')),
            React.createElement(Button, { disabled: chatLoading, className: 'register-btn sf-pro-semibold color-black', sx: {
                    borderRadius: '20px',
                    height: '56px',
                    fontSize: '17px',
                    lineHeight: '20.29px',
                }, fullWidth: true, onClick: function () {
                    handleCloseMatchScreen();
                    setIsActive(false);
                } }, chatLoading ? (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'white' } })) : (t('maybeLater'))))));
};
export default MatchOverlay;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import instagramSvg from '../../assets/instagram.svg';
import tiktokSvg from '../../assets/tiktok.svg';
// import { Slide, toast } from 'react-toastify';
import { ESocials } from '../../types/queens.types';
import { useUnlockSocialMutation } from '../../redux/apiSlice/queensUser.slice';
import useUserId from '../../hooks/useUserId';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { decrementEnergy } from '../../redux/apiSlice/userInfo.slice';
import { useDispatch } from 'react-redux';
import { zodiacSigns } from '../../data/general.data';
import { useTranslation } from 'react-i18next';
import { redirectToSocialMedia } from '../../utils/general.utils';
var LockedSocials = function (_a) {
    var _b, _c, _d, _e;
    var targetUserId = _a.targetUserId, socialMedia = _a.socialMedia, zodiacSign = _a.zodiacSign;
    var _f = useState(!((_b = socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.INSTAGRAM) === null || _b === void 0 ? void 0 : _b.value)), isInstagramLocked = _f[0], setIsInstagramLocked = _f[1];
    var _g = useState(!((_c = socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.TIK_TOK) === null || _c === void 0 ? void 0 : _c.value)), isTiktokLocked = _g[0], setIsTiktokLocked = _g[1];
    var _h = useState(((_d = socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.INSTAGRAM) === null || _d === void 0 ? void 0 : _d.value) || 'username'), instagram = _h[0], setInstagram = _h[1];
    var _j = useState(((_e = socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.TIK_TOK) === null || _e === void 0 ? void 0 : _e.value) || 'username'), tiktok = _j[0], setTiktok = _j[1];
    var _k = useState(null), actionType = _k[0], setActionType = _k[1];
    var userId = useUserId();
    var unlockSocials = useUnlockSocialMutation()[0];
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var handleUnlock = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var link, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setActionType(type);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, unlockSocials({
                            targetUserId: String(targetUserId),
                            socialMediaType: type,
                            userId: userId,
                        }).unwrap()];
                case 2:
                    link = _a.sent();
                    dispatch(decrementEnergy(5));
                    setActionType(null);
                    if (type === ESocials.INSTAGRAM) {
                        setInstagram(link.socialLink);
                        setIsInstagramLocked(false);
                    }
                    else {
                        setTiktok(link.socialLink);
                        setIsTiktokLocked(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                mb: '10px',
            }, gap: 3 },
            React.createElement("img", { src: zodiacSigns[zodiacSign.toLowerCase()], width: '25px', height: '25px' }),
            React.createElement(Box, { sx: { width: '150px' } },
                React.createElement(Typography, { className: 'karla fs-18 section-title w-500' }, t('zodiac')),
                React.createElement(Box, null,
                    React.createElement(Typography, { className: 'karla w-500', sx: { fontSize: '20px !important' } }, t(zodiacSign.toLowerCase()))))),
        !!(socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.INSTAGRAM) && (React.createElement(Box, { sx: {
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                mb: '10px',
            }, gap: 3 },
            React.createElement("img", { src: instagramSvg, width: '25px', height: '25px' }),
            React.createElement(Box, { sx: { width: '120px' } },
                React.createElement(Typography, { className: 'karla fs-18 section-title w-500' }, "Instagram"),
                React.createElement(Box, { sx: {
                        filter: isInstagramLocked ? 'blur(8px)' : '',
                    } },
                    React.createElement(Typography, { className: 'karla w-500', sx: {
                            fontSize: '20px !important',
                            textDecoration: 'underline',
                            wordWrap: 'break-word',
                        }, onClick: function () {
                            instagram !== 'username' &&
                                redirectToSocialMedia('instagram', instagram);
                        } }, instagram))),
            isInstagramLocked && (React.createElement(LoadingButton, { className: 'sf-pro chip-button-unlock', variant: 'outlined', onClick: function () { return handleUnlock(ESocials.INSTAGRAM); } },
                React.createElement("span", { style: { opacity: 0.4 } }, t('unlock_inbox_profile')),
                ' ',
                "\u26A1 5",
                actionType === ESocials.INSTAGRAM && (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'black' } })))))),
        !!(socialMedia === null || socialMedia === void 0 ? void 0 : socialMedia.TIK_TOK) && (React.createElement(Box, { sx: {
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                mb: '10px',
            }, gap: 3 },
            React.createElement("img", { src: tiktokSvg, width: '25px', height: '25px' }),
            React.createElement(Box, { sx: { width: '120px' } },
                React.createElement(Typography, { className: 'karla fs-18 section-title' }, "TikTok"),
                React.createElement(Box, { sx: {
                        filter: isTiktokLocked ? 'blur(8px)' : '',
                    } },
                    React.createElement(Typography, { className: 'karla w-500', sx: {
                            fontSize: '20px !important',
                            textDecoration: 'underline',
                            wordWrap: 'break-word',
                        }, onClick: function () {
                            tiktok !== 'username' &&
                                redirectToSocialMedia('tiktok', tiktok);
                        } }, tiktok))),
            isTiktokLocked && (React.createElement(LoadingButton, { className: 'sf-pro chip-button-unlock', variant: 'outlined', onClick: function () { return handleUnlock(ESocials.TIK_TOK); } },
                React.createElement("span", { style: { opacity: 0.4 } }, t('unlock_inbox_profile')),
                ' ',
                "\u26A1 5",
                actionType === ESocials.TIK_TOK && (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'black' } }))))))));
};
export default LockedSocials;

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigatorOnLine } from '../../hooks/useNavigatorOnline';
export var NetworkStatusIndicator = function () {
    // const { show, close } = useDisplayMessage();
    var isOnline = useNavigatorOnLine();
    var firstUpdate = React.useRef(true);
    React.useLayoutEffect(function () {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
    }, [isOnline]);
    var style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (React.createElement(React.Fragment, null, !isOnline && (React.createElement(Box, { className: 'overlay z-100' },
        React.createElement(Box, { className: 'overlay-content notification-content', sx: { paddingLeft: '5%', paddingRight: '5%' } },
            React.createElement(Stack, { className: 'notification-overlay', alignItems: 'center' },
                React.createElement(Typography, { className: 'welcome-title' }, "You are currently offline"),
                React.createElement(Box, { sx: { display: 'flex' } },
                    React.createElement(CircularProgress, null)),
                React.createElement(Typography, { className: 'w-500 roboto-flex' }, "Check your internet connection!")))))));
};

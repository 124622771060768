var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import WebApp from '@twa-dev/sdk';
import { toast } from 'react-toastify';
import { CountrySelect } from '../../../components/countrySelect';
import CustomDatePicker from '../../../components/customDatePicker';
import CustomSelect from '../../../components/customSelect';
import ImageUpload from '../../../components/imageUpload';
import { genders } from '../../../data/general.data';
import instagramSvg from '../../../assets/instagram.svg';
import tiktokSvg from '../../../assets/tiktok.svg';
import useUserId from '../../../hooks/useUserId';
import { usePauseProfileMutation, useUpdateProfileMutation, } from '../../../redux/apiSlice/queensUser.slice';
import { addImageToGallery, removeImageFromGallery, setPrimaryPhoto, updateOnboardingQuestions, } from '../../../redux/apiSlice/userInfo.slice';
import { store } from '../../../redux/store';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
var EditProfle = function () {
    var navigate = useNavigate();
    var _a = useState(false), clicked = _a[0], setClicked = _a[1];
    var _b = useState(false), isMainPhotoRemoved = _b[0], setIsMainPhotoRemoved = _b[1];
    var maxLength = 200;
    var updateProfile = useUpdateProfileMutation()[0];
    var _c = usePauseProfileMutation(), pauseProfile = _c[0], isLoading = _c[1].isLoading;
    var _d = useSelector(function (state) { return state.userInfo.userData; }), name = _d.name, countryName = _d.countryName, cityName = _d.cityName, description = _d.description, birthDate = _d.birthDate, gender = _d.gender, previousCityName = _d.previousCityName, mainPicture = _d.primaryProfilePicture, gallery = _d.pictureGallery, socialMediaLinks = _d.socialMediaLinks;
    var data = useSelector(function (state) { return state.userInfo; });
    var _e = useState(false), isDataChanged = _e[0], setIsDataChanged = _e[1];
    var _f = useState(false), isCountryDrawerVisible = _f[0], setIsCountryDrawerVisible = _f[1];
    var userId = useUserId();
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var handleImageUpload = function (isMain, url) {
        setIsDataChanged(true);
        if (isMain) {
            dispatch(setPrimaryPhoto(url));
            setIsMainPhotoRemoved(false);
        }
        else {
            dispatch(addImageToGallery(url));
        }
    };
    var handleRemoveImg = function (isMain, url) {
        setIsDataChanged(true);
        if (isMain) {
            dispatch(setPrimaryPhoto(null));
            setIsMainPhotoRemoved(true);
        }
        else {
            dispatch(removeImageFromGallery(url));
        }
    };
    var showToast = function (message) {
        toast(message, {
            position: 'top-center',
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            closeButton: false,
        });
    };
    var handleUpdateUserInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!isDataChanged) return [3 /*break*/, 2];
                    if (isMainPhotoRemoved) {
                        showToast('Main photo is mandatory!');
                        return [2 /*return*/];
                    }
                    if (gallery.length < 2) {
                        showToast('At least 3 images!');
                        return [2 /*return*/];
                    }
                    showToast(t('updating'));
                    return [4 /*yield*/, updateProfile(__assign(__assign({}, data.onboardingQuestions), { mainPicture: mainPicture, pictureGallery: gallery, userId: userId })).unwrap()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    navigate("/user");
                    WebApp.BackButton.offClick(handlerClick);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    showToast('Something went wrong');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // const onPrevLocationPick = (
    //   cityName: string,
    //   countryName: string,
    //   latitude?: number,
    //   longitude?: number
    // ) => {
    //   const previousCity = `${cityName},${countryName}`;
    //   store.dispatch(updateOnboardingQuestions({ previousCity }));
    // };
    var onLocationPick = function (cityName, countryName, latitude, longitude, zoneId) {
        var location = { cityName: cityName, countryName: countryName, latitude: latitude, longitude: longitude, zoneId: zoneId };
        store.dispatch(updateOnboardingQuestions({ location: location }));
        setIsDataChanged(true);
        setIsCountryDrawerVisible(false);
    };
    var onUpdate = function (key, value) {
        var _a;
        setIsDataChanged(true);
        store.dispatch(updateOnboardingQuestions((_a = {}, _a[key] = value, _a)));
    };
    var handlePauseProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, pauseProfile({ userId: userId }).unwrap()];
                case 1:
                    _a.sent();
                    showToast('Profile is paused');
                    navigate('/paused');
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    showToast('Something went wrong');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handlerClick = function () {
        if (window.location.pathname === '/edit-profile' && !clicked) {
            if (isCountryDrawerVisible) {
                setIsCountryDrawerVisible(false);
                return;
            }
            setClicked(true);
            handleUpdateUserInfo();
        }
        else {
            navigate('/user');
        }
    };
    useEffect(function () {
        WebApp.BackButton.onClick(handlerClick);
        return function () { return WebApp.BackButton.offClick(handlerClick); };
    }, [data, isDataChanged, isCountryDrawerVisible]);
    return (React.createElement(Box, { component: motion.div, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 }, display: 'flex', flexDirection: 'column', sx: { height: 'calc(100vh)', overflowY: 'scroll' }, gap: 2, pt: '5%', pb: '10%' },
        React.createElement(ImageUpload, { onAddImage: handleImageUpload, handleRemoveImg: handleRemoveImg, mainPhoto: mainPicture, initialImages: gallery }),
        React.createElement("input", { className: 'onboarding-text-input karla', placeholder: t('name_input_placeholder'), defaultValue: name, onChange: function (e) { return onUpdate('name', e.target.value); }, maxLength: 24 }),
        React.createElement("div", { className: 'textarea-container' },
            React.createElement("textarea", { className: 'onboarding-text-area karla', defaultValue: description, onChange: function (e) { return onUpdate('aboutYou', e.target.value); }, maxLength: maxLength, placeholder: t('description_input_placeholder') }),
            React.createElement("div", { className: 'char-counter' }, description === null || description === void 0 ? void 0 :
                description.length,
                "/",
                maxLength)),
        React.createElement(Typography, { className: 'karla onboarding-step-title' }, t('personal_info_title')),
        React.createElement(CustomDatePicker, { onChange: function (value) { return onUpdate('birthDate', value); }, defaultValue: birthDate }),
        React.createElement(CustomSelect, { onChange: function (value) { return onUpdate('gender', value); }, title: t('gender'), options: genders.map(function (g) { return ({
                value: g.value,
                label: t(g.label),
            }); }), defaultValue: gender }),
        React.createElement(Typography, { className: 'karla onboarding-step-title' }, t('location')),
        React.createElement(CountrySelect, { onChange: onLocationPick, onOpen: function (val) {
                setIsCountryDrawerVisible(val);
            }, backClicked: isCountryDrawerVisible, label: t('city_input_title'), initValue: "".concat(cityName, ", ").concat(countryName) }),
        React.createElement(Typography, { className: 'karla onboarding-step-title' }, t('social_title')),
        React.createElement("input", { className: 'onboarding-text-input karla', placeholder: 'instagram.com/username', onChange: function (e) { return onUpdate('instagram', e.target.value); }, style: {
                backgroundImage: "url(".concat(instagramSvg, ")"),
                backgroundPosition: '10px ',
                backgroundRepeat: 'no-repeat',
                backgroundSize: ' 22px',
                paddingLeft: '40px',
            }, defaultValue: socialMediaLinks === null || socialMediaLinks === void 0 ? void 0 : socialMediaLinks.INSTAGRAM }),
        React.createElement("input", { style: {
                backgroundImage: "url(".concat(tiktokSvg, ")"),
                backgroundPosition: '10px ',
                backgroundRepeat: 'no-repeat',
                backgroundSize: ' 22px',
                paddingLeft: '40px',
            }, className: 'onboarding-text-input karla', placeholder: 'tiktok.com/@username', onChange: function (e) { return onUpdate('tiktok', e.target.value); }, defaultValue: socialMediaLinks === null || socialMediaLinks === void 0 ? void 0 : socialMediaLinks.TIK_TOK }),
        React.createElement(Button, { className: 'register-btn sf-pro-bold color-black', sx: {
                borderRadius: '20px',
                backgroundColor: 'transparent',
                height: '56px !important',
                border: '1px solid black',
                padding: '10px',
                py: '18px',
                fontSize: '17px !important',
                lineHeight: '20.29px',
                ':hover': {
                    backgroundColor: 'transparent !important',
                },
            }, fullWidth: true, onClick: handlePauseProfile },
            t('pause_profile'),
            isLoading && (React.createElement(CircularProgress, { size: 16, sx: { mr: 1, color: 'white' } })))));
};
export default EditProfle;

import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAge } from '../../../utils/general.utils';
var HeaderSection = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), name = _a.name, countryName = _a.countryName, cityName = _a.cityName, birthDate = _a.birthDate, primaryProfilePicture = _a.primaryProfilePicture;
    var age = getAge(birthDate);
    return (React.createElement(Box, { display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3 },
        React.createElement(Avatar, { alt: name, src: primaryProfilePicture, sx: { width: 117, height: 117 } }),
        React.createElement(Box, null,
            React.createElement(Typography, { className: 'reckless-32' },
                name,
                " ",
                React.createElement("span", { className: 'reckless-light' }, age)),
            React.createElement(Typography, { variant: 'body1', className: 'karla w-500 fs-18' },
                cityName,
                ", ",
                countryName))));
};
export default HeaderSection;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserEndpoints } from '../../urls/general.urls';
import { hashPassword, userId } from '../../utils/general.utils';
export var userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['user', 'swipeCandidates', 'referrals', 'matches'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://lovecoinbackend.xyz',
        // headers: {
        //   authentication: hashPassword(),
        // },
    }),
    endpoints: function (builder) { return ({
        getUser: builder.query({
            query: function () {
                return {
                    url: "/".concat(userId, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
            providesTags: ['user'],
        }),
        updateLetsGo: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.LETS_GO, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                    body: body,
                };
            },
        }),
        updateGender: builder.mutation({
            query: function (_a) {
                var gender = _a.gender;
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.GENDER, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                    body: { userId: userId, gender: gender },
                };
            },
            invalidatesTags: ['user'],
        }),
        updateProfilePicture: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.PROFILE_PICTURE, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                    body: body,
                };
            },
        }),
        deleteProfilePicture: builder.mutation({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.PROFILE_PICTURE, "?authToken=").concat(hashPassword()),
                    method: 'DELTE',
                };
            },
        }),
        getSwipes: builder.query({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SWIPES, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
            providesTags: ['swipeCandidates'],
        }),
        swipe: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SWIPE, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                    body: body,
                };
            },
        }),
        getReferrals: builder.query({
            query: function (_a) {
                var page = _a.page;
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.REFERRALS, "?page=").concat(page, "&authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
            providesTags: ['referrals'],
        }),
        getTopBoard: builder.query({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.TOP_BOARD, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
        }),
        updateInterestedIn: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.INTERESTED_IN, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                    body: {
                        userId: "".concat(userId),
                        interestedIn: body.gender,
                    },
                };
            },
            invalidatesTags: ['user', 'swipeCandidates'],
        }),
        resetSwipes: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.RESTE_SWIPES, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                };
            },
            invalidatesTags: ['user', 'swipeCandidates'],
        }),
        collectBonuses: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.REFERRALS, "/").concat(UserEndpoints.BONUS, "/").concat(UserEndpoints.COLLECT, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                };
            },
            invalidatesTags: ['user', 'swipeCandidates', 'referrals'],
        }),
        changeLanguage: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SETTINGS, "/").concat(UserEndpoints.LANGUAGE, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                    body: body,
                };
            },
            invalidatesTags: ['user'],
        }),
        getMatches: builder.query({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.MATCHES, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
            providesTags: ['matches'],
        }),
        markSeenMatches: builder.mutation({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SEE, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                };
            },
            invalidatesTags: ['user'],
        }),
        unmatch: builder.mutation({
            query: function (_a) {
                var matchId = _a.matchId;
                return {
                    url: "/".concat(userId, "/").concat(matchId, "/").concat(UserEndpoints.CANCEL, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                };
            },
            invalidatesTags: ['matches'],
        }),
        getBotUsername: builder.query({
            query: function () {
                return {
                    url: "/".concat(UserEndpoints.BOT_NAME, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
        }),
        markViolation: builder.mutation({
            query: function (_a) {
                var targetUserId = _a.targetUserId, reason = _a.reason;
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.VIOLATION, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                    body: { targetUserId: targetUserId, reason: reason },
                };
            },
        }),
        login: builder.mutation({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.LOGIN, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                };
            },
        }),
        getPotentialSexCouples: builder.query({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.POTENTIAL_SEX_COUPLE, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
        }),
        getSexMatches: builder.query({
            query: function (_a) {
                var page = _a.page;
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SEX_PREFERENCE, "/").concat(UserEndpoints.MATCHES, "?page=").concat(page, "&authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
        }),
        sexAnswer: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SEX_PREFERENCE, "?authToken=").concat(hashPassword()),
                    method: 'POST',
                    body: body,
                };
            },
        }),
        sexUnmatch: builder.mutation({
            query: function (_a) {
                var matchId = _a.matchId;
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.SEX_PREFERENCE, "/").concat(UserEndpoints.MATCHES, "/").concat(matchId, "/").concat(UserEndpoints.CANCEL, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                };
            },
        }),
        getCountries: builder.query({
            query: function () {
                return {
                    url: "/".concat(UserEndpoints.COUNTRIES, "?authToken=").concat(hashPassword()),
                    method: 'GET',
                };
            },
        }),
        changeCountry: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.COUNTRY, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                    body: body,
                };
            },
            invalidatesTags: ['user'],
        }),
    }); },
});
export var useGetUserQuery = userApi.useGetUserQuery, useLazyGetUserQuery = userApi.useLazyGetUserQuery, useUpdateLetsGoMutation = userApi.useUpdateLetsGoMutation, useUpdateGenderMutation = userApi.useUpdateGenderMutation, useUpdateProfilePictureMutation = userApi.useUpdateProfilePictureMutation, useDeleteProfilePictureMutation = userApi.useDeleteProfilePictureMutation, useLazyGetSwipesQuery = userApi.useLazyGetSwipesQuery, useSwipeMutation = userApi.useSwipeMutation, useLazyGetReferralsQuery = userApi.useLazyGetReferralsQuery, useGetTopBoardQuery = userApi.useGetTopBoardQuery, useUpdateInterestedInMutation = userApi.useUpdateInterestedInMutation, useResetSwipesMutation = userApi.useResetSwipesMutation, useCollectBonusesMutation = userApi.useCollectBonusesMutation, useChangeLanguageMutation = userApi.useChangeLanguageMutation, useLazyGetMatchesQuery = userApi.useLazyGetMatchesQuery, useUnmatchMutation = userApi.useUnmatchMutation, useMarkSeenMatchesMutation = userApi.useMarkSeenMatchesMutation, useGetBotUsernameQuery = userApi.useGetBotUsernameQuery, useGetSwipesQuery = userApi.useGetSwipesQuery, useMarkViolationMutation = userApi.useMarkViolationMutation, useLoginMutation = userApi.useLoginMutation, useGetSexMatchesQuery = userApi.useGetSexMatchesQuery, useGetPotentialSexCouplesQuery = userApi.useGetPotentialSexCouplesQuery, useSexAnswerMutation = userApi.useSexAnswerMutation, useLazyGetPotentialSexCouplesQuery = userApi.useLazyGetPotentialSexCouplesQuery, useSexUnmatchMutation = userApi.useSexUnmatchMutation, useGetCountriesQuery = userApi.useGetCountriesQuery, useChangeCountryMutation = userApi.useChangeCountryMutation;

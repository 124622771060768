export var EQueensUrls;
(function (EQueensUrls) {
    EQueensUrls["USERS"] = "users";
    EQueensUrls["STORAGE"] = "storage";
    EQueensUrls["UNLOCK"] = "unlock";
    EQueensUrls["SWIPE"] = "swipe";
    EQueensUrls["PASSIVE_MINING"] = "passive-maining";
    EQueensUrls["START"] = "start";
    EQueensUrls["COLLECT"] = "collect";
    EQueensUrls["ONBOARD"] = "onboard";
    EQueensUrls["MATCHES"] = "matches";
    EQueensUrls["SEE"] = "see";
    EQueensUrls["ENERGY"] = "energy";
    EQueensUrls["BONUS"] = "bonus";
    EQueensUrls["REFERRALS"] = "referrals";
    EQueensUrls["DETAILS"] = "details";
    EQueensUrls["FILES"] = "files";
    EQueensUrls["ITEMS"] = "items";
    EQueensUrls["TASKS"] = "tasks";
    EQueensUrls["PICK"] = "pick";
    EQueensUrls["COMPLETE"] = "complete";
    EQueensUrls["PROFILE"] = "profile";
    EQueensUrls["LOOKING_FOR"] = "looking-for";
    EQueensUrls["PAUSE"] = "pause";
    EQueensUrls["UNPAUSE"] = "unpause";
    EQueensUrls["INBOX"] = "incoming";
    EQueensUrls["UNBLUR"] = "unblur";
    EQueensUrls["GO_TO_CHAT"] = "go-to-chat";
    EQueensUrls["LOGIN"] = "login";
    EQueensUrls["STEP"] = "step";
    EQueensUrls["ONBOARDING"] = "onboarding";
})(EQueensUrls || (EQueensUrls = {}));

import { Stack, Typography } from '@mui/material';
import WebApp from '@twa-dev/sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Slide, toast } from 'react-toastify';
import Onboarding from '.';
import { CountrySelect } from '../../../components/countrySelect';
import CustomDatePicker from '../../../components/customDatePicker';
import CustomSelect from '../../../components/customSelect';
import { genders } from '../../../data/general.data';
import { updateOnboardingQuestions } from '../../../redux/apiSlice/userInfo.slice';
import { store } from '../../../redux/store';
import { EOnboardingSteps } from '../../../types/queens.types';
import StepHeader from './components/StepHeader';
var PersonalInfo = function () {
    var _a = useSelector(function (state) { return state.userInfo.userData; }), storeBirthDate = _a.birthDate, storeGender = _a.gender, cityName = _a.cityName, countryName = _a.countryName, latitude = _a.latitude, longitude = _a.longitude, zoneId = _a.zoneId;
    var _b = useState(!!storeBirthDate), birthDate = _b[0], setBirthDate = _b[1];
    var _c = useState(!!storeGender), gender = _c[0], setGender = _c[1];
    var _d = useState(!!latitude), location = _d[0], setLocation = _d[1];
    var t = useTranslation().t;
    var _e = useState(false), isCountryDrawerVisible = _e[0], setIsCountryDrawerVisible = _e[1];
    if (isCountryDrawerVisible) {
        WebApp.BackButton.show();
    }
    else {
        if (WebApp.BackButton.isVisible) {
            WebApp.BackButton.hide();
        }
    }
    var handlerClick = function () {
        setIsCountryDrawerVisible(false);
    };
    useEffect(function () {
        WebApp.BackButton.onClick(handlerClick);
        return function () { return WebApp.BackButton.offClick(handlerClick); };
    }, [isCountryDrawerVisible]);
    var onDatePick = function (birthDate) {
        var birthdate = new Date(birthDate);
        var today = new Date();
        var age = today.getFullYear() -
            birthdate.getFullYear() -
            (today <
                new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate())
                ? 1
                : 0);
        if (age < 18) {
            toast(t('age_restrict'), {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                closeButton: false,
                transition: Slide,
            });
        }
        else {
            setBirthDate(!!birthDate);
            store.dispatch(updateOnboardingQuestions({ birthDate: birthDate }));
        }
    };
    var onGenderPick = function (gender) {
        setGender(!!gender);
        store.dispatch(updateOnboardingQuestions({ gender: gender }));
    };
    var onLocationPick = function (cityName, countryName, latitude, longitude, zoneId) {
        setLocation(!!cityName);
        var location = { cityName: cityName, countryName: countryName, latitude: latitude, longitude: longitude, zoneId: zoneId };
        store.dispatch(updateOnboardingQuestions({ location: location }));
    };
    useEffect(function () {
        store.dispatch(updateOnboardingQuestions({
            birthDate: storeBirthDate,
            gender: storeGender,
            location: { cityName: cityName, countryName: countryName, latitude: latitude, longitude: longitude, zoneId: zoneId },
        }));
    }, []);
    console.log({ birthDate: birthDate, gender: gender, location: location });
    return (React.createElement(Onboarding, { nextPage: 'looking-for', disabled: !birthDate || !gender || !location, step: EOnboardingSteps.ONBOARD_PERSONAL_INFO },
        React.createElement(Stack, { gap: 2, className: 'w-full' },
            React.createElement(StepHeader, { title: t('personal_info_title'), step: 2 }),
            React.createElement(CustomDatePicker, { onChange: onDatePick, defaultValue: storeBirthDate }),
            React.createElement(CustomSelect, { onChange: onGenderPick, title: t('gender'), options: genders.map(function (g) { return ({
                    value: g.value,
                    label: t(g.label),
                }); }), defaultValue: storeGender }),
            React.createElement(Typography, { className: 'karla onboarding-step-title', sx: {
                    textTransform: 'uppercase',
                    fontSize: '15px !important',
                    WebkitTextFillColor: '#7A7A7A',
                    mt: '16px',
                    px: '8px',
                } }, t('location')),
            React.createElement(CountrySelect, { onChange: onLocationPick, label: t('city_input_title'), onOpen: function (val) {
                    setIsCountryDrawerVisible(val);
                }, backClicked: isCountryDrawerVisible }))));
};
export default PersonalInfo;

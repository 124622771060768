import { Box, Pagination, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FriendItem from './FriendItem';
var FriendsList = function (_a) {
    var handleNextPage = _a.handleNextPage, pageCount = _a.pageCount, content = _a.content, empty = _a.empty, icon = _a.icon, totalPages = _a.totalPages;
    var t = useTranslation().t;
    if (empty) {
        return null;
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2, overflow: 'scroll' },
        React.createElement(Typography, { align: 'left', className: 'sf-pro-bold', sx: {
                fontSize: '17px !important',
                lineHeight: '22.29px',
            } }, t('invited_friends')), content === null || content === void 0 ? void 0 :
        content.map(function (_a, i) {
            var name = _a.name, referralsCount = _a.referralsCount, earning = _a.earning;
            return (React.createElement(FriendItem, { icon: icon, key: i, name: name, referralsCount: referralsCount, earning: earning }));
        }),
        React.createElement(Box, { display: 'flex', justifyContent: 'center' },
            React.createElement(Pagination, { hidden: empty || totalPages <= 1, count: pageCount, shape: 'rounded', onChange: function (_, page) { return handleNextPage(page); } }))));
};
export default FriendsList;

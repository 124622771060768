import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserEndpoints } from '../../urls/general.urls';
import { hashPassword, userId } from '../../utils/general.utils';
export var thirdPartApi = createApi({
    reducerPath: 'thirdPartApi',
    tagTypes: [],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://telegram.api.lovecoinbackend.xyz',
        headers: {},
    }),
    endpoints: function (builder) { return ({
        checkSubscription: builder.mutation({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(UserEndpoints.CHANEL_SUBSCRIPTION, "?authToken=").concat(hashPassword()),
                    method: 'PATCH',
                };
            },
        }),
    }); },
});
export var useCheckSubscriptionMutation = thirdPartApi.useCheckSubscriptionMutation;

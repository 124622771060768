var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Typography, Avatar } from '@mui/material';
import React from 'react';
var FriendItem = function (_a) {
    var name = _a.name, earning = _a.earning, referralsCount = _a.referralsCount, icon = _a.icon;
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: '#6F5655',
            },
            children: "".concat(name.split(' ')[0][0]),
        };
    }
    return (React.createElement(Box, { sx: {
            background: '#2E0909',
            opacity: '50%',
            borderRadius: '10px',
            display: 'flex',
            py: '12px',
            px: '16pt',
            alignItems: 'center',
            gap: '10px',
        } },
        React.createElement(Avatar, __assign({}, stringAvatar(name), { className: 'color-white' })),
        React.createElement(Box, { display: 'flex', justifyContent: 'space-between', gap: 3, alignItems: 'center', sx: { width: '100%' } },
            React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                React.createElement(Typography, { className: 'color-white sf-pro-semibold', sx: {
                        fontSize: '14px !important',
                    }, align: 'left' }, name),
                React.createElement(Box, { display: 'flex', alignContent: 'center', gap: 1 },
                    React.createElement("img", { src: icon }),
                    React.createElement(Typography, { className: 'color-white sf-pro-semibold', sx: {
                            fontSize: '12px !important',
                            lineHeight: '22px',
                        }, align: 'left' }, referralsCount))),
            React.createElement(Typography, { className: 'color-white sf-pro-bold', sx: {
                    fontSize: '12px !important',
                    lineHeight: '22px',
                } },
                "\uD83D\uDC8E ",
                earning))));
};
export default FriendItem;

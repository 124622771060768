import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import plusIcon from '../../assets/plus.svg';
import closeIcon from '../../assets/close_round.svg';
var SecondaryImage = function (_a) {
    var url = _a.url, onClick = _a.onClick, isLoading = _a.isLoading, length = _a.length, removeImg = _a.removeImg;
    var _b = useState(false), clicked = _b[0], setClicked = _b[1];
    if (length >= 6) {
        return null;
    }
    return (React.createElement("div", { className: 'upload-image-secondary', style: {
            backgroundImage: "".concat(url ? "url(".concat(url, ")") : 'none'),
            borderBottom: 'solid 1px #dfdfdf',
            backgroundSize: 'cover',
            position: 'relative',
        }, onClick: function () {
            onClick();
            setClicked(true);
        } },
        !url &&
            (isLoading && clicked ? (React.createElement(CircularProgress, null)) : (React.createElement("img", { src: plusIcon, width: 39, height: 39 }))),
        url && React.createElement(CircularProgress, { sx: { zIndex: '-1' } }),
        url && (React.createElement("img", { src: closeIcon, width: 24, height: 24, onClick: function (e) {
                removeImg(url, false);
                e.stopPropagation();
            }, style: {
                position: 'absolute',
                top: '5px',
                right: '5px',
            } }))));
};
export default SecondaryImage;

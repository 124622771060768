var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import ReactionBar from '../../../components/reactionBar';
import SwipeableCard from './CandidatesWrapper';
import { useGetSwipesQuery, useSwipeMutation, } from '../../../redux/apiSlice/queensUser.slice';
import useUserId from '../../../hooks/useUserId';
import { EActionType, } from '../../../types/queens.types';
import { useDispatch, useSelector } from 'react-redux';
import { decrementDimond, decrementEnergy, } from '../../../redux/apiSlice/userInfo.slice';
import { toast } from 'react-toastify';
import MatchOverlay from './MatchOverlay';
import EmptyState from './EmptyState';
import LoadingState from './LoadingState';
var SwipeCardList = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var onboarded = _a.onboarded;
    var userId = useUserId();
    var dispatch = useDispatch();
    var _h = useSelector(function (state) { return state.userInfo.userData; }), energy = _h.energy, diamond = _h.diamond;
    var _j = useState([]), cards = _j[0], setCards = _j[1];
    var _k = useState(false), existsDataOnFetch = _k[0], setExistsDataOnFetch = _k[1];
    var _l = useState(), matchedData = _l[0], setMatchedData = _l[1];
    var _m = useGetSwipesQuery({ userId: userId }, { skip: !userId || !onboarded }), data = _m.data, isLoading = _m.isLoading, refetch = _m.refetch;
    var _o = useState(null), swipeDirection = _o[0], setSwipeDirection = _o[1];
    var _p = useState(null), actionType = _p[0], setActionType = _p[1];
    var swipe = useSwipeMutation()[0];
    var handleCloseMatchScreen = function () {
        setMatchedData(null);
    };
    var handleSwipe = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var swipedIds, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCards(function (prevCards) { return prevCards.filter(function (card) { return card.id !== id; }); });
                    setSwipeDirection(null);
                    swipedIds = JSON.parse(sessionStorage.getItem('swipedIds') || '[]');
                    swipedIds.push(id);
                    sessionStorage.setItem('swipedIds', JSON.stringify(swipedIds));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, swipe({
                            userId: userId,
                            targetUserId: String(id),
                            actionType: actionType,
                        }).unwrap()];
                case 2:
                    response = _a.sent();
                    setMatchedData(response);
                    setSwipeDirection(null);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var preloadImage = function (url) {
        var img = new Image();
        img.src = url;
    };
    var swipeCard = function (direction, type, price) {
        if ((type === EActionType.CROWN && diamond <= 0) ||
            ((type === EActionType.FIRE || type === EActionType.LIKE) &&
                energy < 10) ||
            ((type === EActionType.LAUGH || type === EActionType.HANDSHAKE) &&
                energy < 1)) {
            toast('Not enough energy or diamonds', {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                closeButton: false,
            });
            return;
        }
        if (type === EActionType.CROWN) {
            dispatch(decrementDimond(price));
        }
        else {
            dispatch(decrementEnergy(price));
        }
        setSwipeDirection(direction);
        setActionType(type);
    };
    useEffect(function () {
        if (data) {
            var copyData = structuredClone(data);
            copyData.reverse();
            setCards(copyData);
            //@ts-ignore
            setExistsDataOnFetch(!!copyData.length);
        }
    }, [data]);
    useEffect(function () {
        if (data &&
            !cards.filter(function (card) { return !swipedIds.includes(card.id); }).length &&
            data.length !== cards.length &&
            existsDataOnFetch) {
            refetch();
        }
    }, [cards]);
    if (isLoading) {
        return React.createElement(LoadingState, null);
    }
    var swipedIds = JSON.parse(sessionStorage.getItem('swipedIds') || '[]');
    var noItems = !(data === null || data === void 0 ? void 0 : data.length) ||
        !cards.filter(function (card) { return !swipedIds.includes(card.id); }).length;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("div", { style: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                } }, noItems && React.createElement(EmptyState, null)),
            React.createElement("div", { style: { position: 'relative' } }, cards
                .filter(function (card) { return !swipedIds.includes(card.id); })
                .slice(-1)
                .map(function (userInfo, index) {
                var list = cards.filter(function (card) { return !swipedIds.includes(card.id); });
                var nextCard = index < list.length - 1 ? list.reverse()[index + 1] : null;
                console.log(nextCard);
                if (nextCard) {
                    __spreadArray([nextCard === null || nextCard === void 0 ? void 0 : nextCard.primaryPicture], nextCard.photoGallery, true).forEach(function (url) {
                        preloadImage(url);
                    });
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement(SwipeableCard, { key: userInfo.id, swipeDirection: swipeDirection, onSwipe: function () { return handleSwipe(userInfo.id); }, userInfo: userInfo })));
            })),
            !noItems && React.createElement(ReactionBar, { onReact: function (v, a, p) { return swipeCard(v, a, p); } })),
        React.createElement(MatchOverlay, { matchName: (_b = matchedData === null || matchedData === void 0 ? void 0 : matchedData.target) === null || _b === void 0 ? void 0 : _b.name, matchPicture: (_c = matchedData === null || matchedData === void 0 ? void 0 : matchedData.target) === null || _c === void 0 ? void 0 : _c.profilePicture, matchType: (_d = matchedData === null || matchedData === void 0 ? void 0 : matchedData.target) === null || _d === void 0 ? void 0 : _d.incomingAction, username: (_e = matchedData === null || matchedData === void 0 ? void 0 : matchedData.target) === null || _e === void 0 ? void 0 : _e.username, show: (matchedData === null || matchedData === void 0 ? void 0 : matchedData.isMatch) || !!((_f = matchedData === null || matchedData === void 0 ? void 0 : matchedData.target) === null || _f === void 0 ? void 0 : _f.targetUserId), handleCloseMatchScreen: handleCloseMatchScreen, myActionType: actionType, targetUserId: (_g = matchedData === null || matchedData === void 0 ? void 0 : matchedData.target) === null || _g === void 0 ? void 0 : _g.targetUserId })));
};
export default SwipeCardList;

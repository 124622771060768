import { Divider, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import editProfileSvg from '../../../assets/editProfile.svg';
import lookingForSvg from '../../../assets/lookingFor.svg';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
var EditSection = function () {
    var navigate = useNavigate();
    var t = useTranslation().t;
    return (React.createElement(List, { sx: { background: 'white', borderRadius: '16px' } },
        React.createElement(ListItem, { button: true, onClick: function () { return navigate('/edit-profile'); } },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: editProfileSvg })),
            React.createElement(ListItemText, { primary: t('edit_profile'), className: 'sf-pro' }),
            React.createElement(ChevronRightIcon, { sx: { fill: 'rgba(147, 147, 147, 1)' } })),
        React.createElement(Divider, { variant: 'inset', component: 'li' }),
        React.createElement(ListItem, { button: true, onClick: function () { return navigate('/edit-looking-for'); } },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: lookingForSvg })),
            React.createElement(ListItemText, { primary: t('looking_for_title'), className: 'sf-pro' }),
            React.createElement(ChevronRightIcon, { sx: { fill: 'rgba(147, 147, 147, 1)' } }))));
};
export default EditSection;

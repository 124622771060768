export var fr = {
    loginDescription: 'Des connexions de haute qualité sur Telegram. Rejoignez la communauté de personnes ambitieuses et belles.',
    create_profile_button: 'Créer un profil',
    login: 'Connexion',
    create_profile_title: 'Créer un profil',
    name_input_placeholder: 'Votre nom',
    description_input_placeholder: 'À propos de vous',
    description_input_example: "Exemple : Designer de Dubaï. J'adore le surf...",
    profile_creation_continue_button: 'Continuer',
    personal_info_title: 'Informations personnelles',
    date_of_birth: 'Date de naissance',
    date_of_birth_choose_select: 'Choisissez une date',
    gender: 'Genre',
    login_description: 'Des connexions de haute qualité sur Telegram. Rejoignez la communauté de personnes ambitieuses et belles.',
    select: 'Sélectionner',
    location: 'Emplacement',
    city_input_title: 'Votre ville',
    previous_city_input_title: 'Ville précédente',
    looking_for_title: 'Recherche',
    age_range: "Tranche d'âge",
    social_title: 'Social',
    optional: 'optionnel',
    cancel: 'Annuler',
    instagram_input_placeholder: 'instagram.com/username',
    tiktok_input_placeholder: 'tiktok.com/@username',
    add_profile_photos_title: 'Ajouter des photos de profil',
    main_photo_title: 'Photo principale',
    at_least_3: 'Téléchargez au moins trois photos. ',
    at_least_info: "Vos photos doivent \u00EAtre centr\u00E9es sur vous\u2014votre visage et votre corps. Assurez-vous d'\u00EAtre la seule personne sur la photo. Pas d'images hors sujet ou d'objets al\u00E9atoires.",
    photo_upload_note: "Au moins 3 photos. Brève description des types de photos acceptables et des restrictions (par exemple, pas de nudité, doit montrer clairement le visage de l'utilisateur).",
    x_of_y: '0 sur 9',
    profile_under_review_title: "Votre profil est en cours d'examen",
    profile_under_review_info: 'Après vérification, vous aurez accès à tous les avantages de Queens :',
    benefit_1: 'Score de compatibilité personnalisé',
    benefit_2: 'Obtenez des récompenses pour la qualité du profil',
    benefir_3: 'Tous les profils sont vérifiés',
    got_it: 'Compris',
    quiz_complete_compatability: 'Complétez le quiz de compatibilité',
    quiz_description: 'Obtenez un score de compatibilité personnalisé basé sur les réponses',
    start_quiz: 'Commencer un quiz',
    zodiac: 'Zodiaque',
    tasks: 'Tâches',
    refs: 'Référés',
    social_tasks: 'Tâches sociales',
    join_telegram_chanel_task_title: 'Rejoignez la chaîne TG de Queens',
    start_task: 'Commencer',
    instagram_follow_task_title: 'Suivez Queens Instagram',
    daily_tasks: 'Tâches quotidiennes',
    start_farming: 'Commencer à cultiver',
    task_completion: 'Vous avez X énergie',
    refs_total_earnings: 'Revenus totaux',
    ref_first_level_earn_percent: '10 % de vos amis',
    ref_second_level_earn_percent: '+2,5 % de leurs parrainages',
    invite_friend: 'Inviter un ami',
    invite: 'Inviter',
    invite_friend_title: 'Invitez des amis et obtenez',
    invite_firned_info: 'Obtenez 10 % des gains de vos amis et un bonus supplémentaire de +2,5 % de leurs amis.',
    send_invite: 'Envoyer une invitation',
    copy_invite_link: 'Copier le lien',
    invited_friends: 'Amis invités',
    edit_profile: 'Modifier le profil',
    looking_for: 'Recherche de',
    contact_support: 'Contacter le support',
    faq: 'FAQ',
    privacy_policy: 'Politique de confidentialité',
    pause_profile: 'Mettre mon profil en pause',
    mutual: 'Mutuel',
    inbox: 'Boîte de réception',
    go_to_chat: 'Aller au chat',
    unlock_inbox_profile: 'Ouvrir',
    profile_unlocked: 'Profil Débloqué',
    open_profile: 'Ouvrir le profil',
    it_is_a_match: "C'est un match !",
    match_description: 'Vous et Glen vous êtes appréciés',
    maybe_later: 'Peut-être plus tard',
    matchTitle: "C'est un match !",
    matchInfo: 'Vous et {{name}} vous êtes appréciés',
    goToChat: 'Aller au chat',
    maybeLater: 'Peut-être plus tard',
    crownMatchTitle: 'Mettez la couronne !',
    crownMatchInfo: 'Félicitations ! Vous pouvez discuter avec {{name}} maintenant',
    popularLocations: 'Lieux populaires',
    searchResults: 'Résultats de recherche',
    mutualEmptyTitle: 'Connexions mutuelles',
    inboxEmptyTitle: 'Réactions de la boîte de réception',
    mutualEmptyInfo: "Lorsque quelqu'un enverra une réaction sur votre profil, nous vous en informerons.",
    unlockProfileMsg: 'Profil déverrouillé',
    unlock: 'Ouvrir',
    openProfile: 'Ouvrir le profil',
    youveGot: 'Vous avez',
    youGot: 'Vous avez',
    start: 'Commencez',
    about: 'À propos',
    claim: 'Réclamation',
    checkBackLater: 'Revenez plus tard',
    noOne: "Il n'y a personne de nouveau autour de vous",
    male: 'Masculin',
    female: 'Femme',
    nonBinary: 'Non-binaire (autre)',
    claimReward: 'Réclamer la récompense',
    profilePaused: 'Profil en pause',
    unpause: 'Reprendre',
    profileUnpaused: 'Le profil est réactivé',
    completed: 'Terminé',
    age_restrict: 'Vous devez avoir 18 ans ou plus pour continuer.',
    updating: 'Mise à jour du profil...',
    needToSubscribe: 'Vous devez vous abonner à la chaîne',
    noEnoughEnergy: "Pas assez d'\u00E9nergie",
    confirm: 'Confirmer',
    aries: 'Bélier',
    taurus: 'Taureau',
    gemini: 'Gémeaux',
    cancer: 'Cancer',
    leo: 'Léo',
    virgo: 'Vierge',
    libra: 'Balance',
    scorpio: 'Scorpion',
    sagittarius: 'Sagittaire',
    capricorn: 'Capricorne',
    aquarius: 'Verseau',
    pisces: 'Poissons',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    question_1: "Qu'est-ce que cette application ?",
    question_1_answer: "C'est une application de rencontres unique intégrée à Telegram. Au lieu du balayage traditionnel \"Oui/Non\", l'application vous permet d'interagir en utilisant diverses réactions qui représentent vos émotions et préférences.",
    question_2: "Quelles réactions puis-je utiliser dans l'application ?",
    question_2_answer_1: "L'application propose cinq types de réactions, chacune avec un coût spécifique :",
    question_2_answer_2: 'Couronne – coûte 1 diamant',
    question_2_answer_3: 'Like – coûte 10 éclairs',
    question_2_answer_4: 'Feu – coûte 10 éclairs',
    question_2_answer_5: 'Rire – coûte 1 éclair',
    question_2_answer_6: 'Poignée de main – coûte 1 éclair',
    question_2_answer_7: "Vous pouvez choisir n'importe laquelle de ces réactions pour interagir avec d'autres utilisateurs.",
    question_3: 'Comment gagner et dépenser des diamants et des éclairs ?',
    question_3_answer: "Vous pouvez gagner des diamants et des éclairs en étant actif dans l'application, en accomplissant certaines tâches et en invitant des amis à rejoindre l'application. Ces ressources sont ensuite utilisées pour envoyer des réactions aux autres utilisateurs ou débloquer des fonctionnalités supplémentaires.",
    question_4: "Comment puis-je discuter avec d'autres utilisateurs ?",
    question_4_answer: "Dans cette version de l'application, il n'y a pas de chat intégré. Cependant, lorsque vous recevez une correspondance avec un autre utilisateur, son nom d'utilisateur Telegram vous sera affiché, vous permettant de démarrer une conversation directement via Telegram.",
    question_5: 'Que se passe-t-il lorsque je reçois des réactions positives ?',
    question_5_answer: "Lorsque vous recevez des réactions positives comme Couronne, J'aime ou Feu, elles seront affichées dans la section des réactions de l'application. Vous verrez qui a envoyé la réaction, et les réactions positives mutuelles seront affichées dans une section séparée. Vous pouvez initier une conversation via Telegram si vous réagissez positivement l'un à l'autre.",
    question_6: "Les réactions neutres apparaissent-elles dans l'application ?",
    question_6_answer: "Non, les réactions neutres comme Rire et Poignée de main n'apparaissent pas dans cette version de l'application. Seules les réactions positives vous seront montrées.",
    question_7: "Dois-je télécharger des photos pour m'inscrire ?",
    question_7_answer: "Oui, vous êtes tenu de télécharger au moins trois photos pour compléter votre inscription. Ces photos doivent clairement montrer votre visage et respecter les directives de contenu de l'application.",
    question_8: "Puis-je modifier mon profil après l'inscription ?",
    question_8_answer: "Oui, vous pouvez modifier les informations de votre profil et vos photos après l'inscription. Il vous suffit d'accéder à la section du profil et d'apporter les modifications nécessaires.",
    question_9: 'Comment fonctionne le système de parrainage ?',
    question_9_answer: 'En invitant des amis à rejoindre l\'application, vous pouvez gagner des diamants. Les récompenses sont accordées lorsque vous cliquez sur le bouton "Démarrer" dans la section des parrainages, et vous recevrez des bonus pour chaque ami qui s\'inscrit via votre lien.',
    question_10: 'Comment fonctionne le système de modération ?',
    question_10_answer: "Après votre inscription, votre profil sera envoyé pour modération. Pendant qu'il est en cours de révision, vous pouvez continuer à utiliser l'application normalement. Si votre profil est approuvé, vous ne remarquerez aucun changement. Si votre profil est refusé, vous serez invité à le soumettre à nouveau avec des modifications.",
    question_11: 'Comment vais-je recevoir les notifications ?',
    question_11_answer: "Toutes les notifications seront envoyées via le bot Telegram lié à votre compte. Ces notifications vous informeront des nouvelles correspondances, des réactions entrantes et d'autres événements importants dans l'application.",
    effective_date: "Date d'entrée en vigueur : 20.08.2024",
    policy: 'POLITIQUE DE CONFIDENTIALITÉ',
    policy_1: "Queens Dating, exploité par QUEENS-DATING LIMITED (nous, notre, ou la Société), s'engage à protéger votre vie privée et à garantir la sécurité de vos données personnelles. Cette Politique de Confidentialité décrit comment nous collectons, utilisons, partageons et protégeons vos informations personnelles lorsque vous utilisez l'application Queens Dating (l'App ou le Service). En utilisant le Service, vous acceptez cette Politique de Confidentialité et consentez à la collecte, à l'utilisation et à la divulgation de vos informations comme décrit ici.",
    policy_2: "Nous comprenons l'importance de votre vie privée et nous engageons à traiter vos informations personnelles de manière responsable et sécurisée.",
    policy_3: '1. Informations que nous collectons',
    policy_4: "Nous collectons différents types d'informations pour fournir et améliorer notre Service, notamment :",
    policy_5: '1.1 Informations personnelles',
    policy_6: "- Données de profil : Lorsque vous créez un compte, nous collectons des informations personnelles telles que votre nom, âge, sexe, adresse e-mail, numéro de téléphone, photo de profil, centres d'intérêt, orientation sexuelle et préférences relationnelles.",
    policy_7: "- Données de localisation : Nous collectons des informations de géolocalisation pour vous aider à vous connecter avec des utilisateurs à proximité. Cela inclut des données en temps réel de votre appareil mobile si vous autorisez l'accès aux services de localisation.",
    policy_8: "- Données de communication : Cela inclut toutes les communications que vous partagez via le Service, telles que des messages, enregistrements vocaux ou échanges de médias avec d'autres utilisateurs.",
    policy_9: "1.2 Données comportementales et d'utilisation",
    policy_10: 'Nous collectons des informations sur la manière dont vous interagissez avec notre App :',
    policy_11: "- Informations d'activité : Cela inclut des détails sur les fonctionnalités que vous utilisez, les utilisateurs avec lesquels vous interagissez, et vos préférences au sein de l'App telles que les balayages, les correspondances, et les interactions.",
    policy_12: "- Informations sur l'appareil : Nous pouvons collecter des détails sur votre appareil, y compris le modèle matériel, le système d'exploitation, la version de l'application, les identifiants de l'appareil tels que l'adresse IP, l'adresse MAC, le type de navigateur et les informations du réseau mobile.",
    policy_13: '1.3 Données collectées automatiquement',
    policy_14: 'Nous pouvons collecter automatiquement certaines données, notamment :',
    policy_15: "- Données de journal : Lorsque vous interagissez avec notre Service, nous collectons des journaux de serveur qui peuvent inclure votre adresse IP, la date et l'heure de votre utilisation, les pages visitées, les plantages de l'application et d'autres activités du système.",
    policy_16: '- Cookies et technologies similaires : Nous utilisons des cookies et des technologies similaires de suivi pour analyser les tendances, administrer le site Web, suivre les mouvements des utilisateurs autour du site et recueillir des informations démographiques.',
    policy_17: '2. Comment nous utilisons vos informations',
    policy_18: 'Nous utilisons les informations que nous collectons à diverses fins pour améliorer votre expérience sur Queens Dating, notamment :',
    policy_19: "- Pour fournir le Service : Vos informations nous aident à fournir, exploiter et maintenir notre Service, notamment en vous connectant à d'autres utilisateurs en fonction de vos préférences et de votre emplacement.",
    policy_20: '- Pour personnaliser votre expérience : Nous utilisons vos informations pour personnaliser votre expérience, telles que suggérer des correspondances potentielles, afficher du contenu et améliorer les recommandations.',
    policy_21: "- Pour améliorer le Service : Nous analysons les données pour comprendre les tendances et les modèles d'utilisation afin d'améliorer les fonctionnalités de notre Service.",
    policy_22: "- Pour le marketing et les promotions : Nous pouvons utiliser vos informations personnelles pour diffuser du contenu promotionnel et de la publicité pertinents pour vous, à la fois dans l'App et par e-mail.",
    policy_23: '- Pour communiquer avec vous : Nous pouvons utiliser vos informations pour vous envoyer des mises à jour, des notifications, des alertes de sécurité et des messages de support.',
    policy_24: '- Pour assurer la sécurité et la sûreté : Nous utilisons les informations pour surveiller les activités des utilisateurs, détecter et prévenir la fraude, et garantir le respect de nos conditions générales.',
    policy_25: '3. Partage de vos informations',
    policy_26: 'Nous respectons votre vie privée et ne partageons vos informations que comme décrit dans cette politique :',
    policy_27: "3.1 Avec d'autres utilisateurs",
    policy_28: "En utilisant le Service, vous acceptez que nous puissions partager les informations de votre profil telles que votre nom, âge, photos et autres données de profil avec d'autres utilisateurs. Soyez prudent quant aux informations que vous divulguez, car vous êtes responsable de ce que vous partagez avec les autres.",
    policy_29: '3.2 Avec des prestataires de services',
    policy_30: "Nous engageons des prestataires de services tiers de confiance pour exécuter certaines fonctions liées à l'entreprise telles que les services d'hébergement, l'analyse de données, le support client, le traitement des paiements et la publicité. Ces prestataires sont tenus de protéger vos informations et de les utiliser uniquement aux fins que nous spécifions.",
    policy_31: "3.3 En cas de transfert d'entreprise",
    policy_32: "Si nous faisons l'objet d'une fusion, d'une acquisition, d'une réorganisation ou d'une vente d'actifs, vos informations peuvent être transférées dans le cadre de cette transaction. Nous vous informerons de tout transfert et des nouvelles pratiques de confidentialité qui s'appliqueront.",
    policy_33: '3.4 Pour se conformer à la loi',
    policy_34: "Nous pouvons partager vos informations lorsque la loi l'exige, par exemple en réponse à une assignation à comparaître, une enquête gouvernementale ou d'autres processus juridiques. Nous pouvons également partager des informations pour protéger les droits, la propriété ou la sécurité de Queens Dating, de nos utilisateurs ou d'autres personnes.",
    policy_35: '4. Transferts internationaux de données',
    policy_36: "Vos informations personnelles peuvent être transférées et conservées sur des serveurs situés en dehors de votre juridiction, y compris dans des pays où les lois sur la protection des données peuvent différer de celles de votre pays d'origine. En utilisant notre Service, vous consentez à ces transferts. Nous prenons des mesures pour nous assurer que vos informations sont traitées de manière sécurisée et conformément à cette Politique de Confidentialité où qu'elles soient traitées.",
    policy_37: '5. Conservation des données',
    policy_38: 'Nous ne conserverons vos informations personnelles que le temps nécessaire pour vous fournir notre Service et pour nous conformer à nos obligations légales. Si vous fermez votre compte, nous supprimerons ou anonymiserons vos informations personnelles, sauf si la loi exige leur conservation ou pour des raisons commerciales légitimes.',
    policy_39: '6. Vos droits et choix',
    policy_40: 'En fonction de votre localisation et des lois applicables, vous pouvez avoir les droits suivants :',
    policy_41: "- Accès à vos informations : Vous pouvez demander l'accès à vos données personnelles et obtenir une copie des informations que nous détenons à votre sujet.",
    policy_42: '- Correction des informations : Vous avez le droit de demander la correction de toute information inexacte ou incomplète.',
    policy_43: "- Suppression des informations : Dans certaines circonstances, vous pouvez demander que nous supprimions vos données personnelles. Notez que nous pouvons conserver certaines informations comme l'exige la loi.",
    policy_44: '- Restriction du traitement : Vous pouvez demander que nous limitions le traitement de vos informations personnelles dans certaines situations.',
    policy_45: '- Portabilité des données : Vous avez le droit de demander le transfert de vos données à un autre prestataire de services.',
    policy_46: '- Retrait du consentement : Lorsque nous nous appuyons sur votre consentement pour traiter vos données, vous avez le droit de retirer ce consentement à tout moment.',
    policy_47: "Pour exercer l'un de ces droits, veuillez nous contacter à [Insérer les informations de contact].",
    policy_48: '7. Sécurité des données',
    policy_49: "Nous nous engageons à maintenir la sécurité de vos informations. Nous mettons en œuvre diverses mesures techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, modification, divulgation ou destruction. Cependant, aucune mesure de sécurité n'est entièrement infaillible et nous ne pouvons garantir la sécurité absolue de vos informations.",
    policy_50: '8. Confidentialité des enfants',
    policy_51: "Notre Service n'est pas destiné aux personnes de moins de 18 ans. Nous ne collectons pas sciemment d'informations personnellement identifiables auprès des enfants de moins de 18 ans. Si nous découvrons que nous avons collecté par inadvertance des informations personnelles d'un enfant, nous prendrons des mesures pour supprimer ces informations dès que possible. Si vous pensez que nous pourrions avoir des informations concernant un enfant, veuillez nous contacter.",
    policy_52: '9. Modifications de cette Politique de Confidentialité',
    policy_53: "Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre pour refléter les changements dans nos pratiques ou les exigences légales. Nous vous informerons de toute modification substantielle en publiant la nouvelle Politique de Confidentialité sur cette page et en mettant à jour la date d'entrée en vigueur. Nous vous encourageons à consulter périodiquement cette Politique de Confidentialité pour vous tenir informé des mises à jour.",
    policy_54: '10. Nous contacter',
    policy_55: 'Si vous avez des questions ou des préoccupations concernant cette Politique de Confidentialité, ou si vous souhaitez exercer vos droits, veuillez nous contacter.',
};
